import {
  createTheme,
  useMediaQuery,
  Container,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  Button,
  Grid,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import aomsin from "../../assets/images/aomsin.png";
import bangkok from "../../assets/images/bangkok.png";
import cimb from "../../assets/images/cimb.png";
import kbank from "../../assets/images/KBANK.png";
import kiatnakin from "../../assets/images/kiatnakin.png";
import krungsri from "../../assets/images/krungsri.png";
import krungthai from "../../assets/images/krungthai.png";
import scb from "../../assets/images/SCB.png";
import tmb from "../../assets/images/tmb.png";
import tnc from "../../assets/images/tnc.png";
import unnamed from "../../assets/images/unnamed.png";
import uob from "../../assets/images/uob.png";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../config/axios";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const bankImages = [
  { name: "aomsin", image: aomsin },
  { name: "bangkok", image: bangkok },
  { name: "cimb", image: cimb },
  { name: "kbank", image: kbank },
  { name: "kiatnakin", image: kiatnakin },
  { name: "krungsri", image: krungsri },
  { name: "krungthai", image: krungthai },
  { name: "scb", image: scb },
  { name: "tmb", image: tmb },
  { name: "tnc", image: tnc },
  { name: "unnamed", image: unnamed },
  { name: "uob", image: uob },
];
const BankPage = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [tab, setTab] = useState(-1);
  const [selected, setSelected] = useState(false);
  const { t, i18n } = useTranslation();
  const [bankData, setBankData] = useState([]);
  const [bankId, setBankId] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const client = useAxiosPrivate(axios);
  const navigate = useNavigate();
  const session: any = useSessionContext();
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const addBankInfo = async (data: any) => {
    try {
      setLoading2(true);
      const dataBody = {
        userId: session?.userId,

        accountDetails: {
          firstName: data.name,
          lastName: data.surname,
          accountNumber: data.account_number,
        },

        bankId: bankId,
      };
      const updatedData = {
        firstName: data.name,
        lastName: data.surname,
      };
      const res = await client.put(API_ROUTES.ADD_BANK, dataBody);
      const response = await client.post(
        API_ROUTES.Update_Profile,
        updatedData
      );
      localStorage.setItem("bankAdded", "true");
      setLoading2(false);
      toast.success("Bank added successfully.");
      // navigate(APP_ROUTES.Dashboard);
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const getBank = async () => {
    try {
      setLoading(true);
      const res = await client.get(API_ROUTES.GET_BANK);
      const temp: any = [];
      res.data.map((ele: any) => {
        const name = ele.name;
        const url = ele.thumbnail;
        const id = ele.id;
        temp.push({ name: name, url: url, id: id });
      });
      setBankData(temp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const bankinfo = localStorage.getItem("bankAdded");
  useEffect(() => {
    getBank();
  }, []);
  useEffect(() => {
    if (localStorage.getItem("bankAdded") == "true") {
      navigate(APP_ROUTES.Dashboard);
    }
  }, [bankinfo]);
  return (
    <Container maxWidth={isSmall ? "sm" : "md"}>
      <Card
        sx={{
          borderRadius: "0.50rem",
          width: isSmall ? "100%" : "90%",
          margin: "0 auto",
          p: 0,
        }}
      >
        <CardContent
          sx={{ width: "90%", margin: "0 auto", pb: "16px !important" }}
        >
          <Typography
            variant="h6"
            color="#D3114C"
            fontWeight={600}
            textAlign="center"
            my={1}
          >
            {t("bank.title1")}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={600}
            textAlign="center"
            my={1}
          >
            {t("bank.title2")}
          </Typography>
          {!selected ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                justifyContent: "center",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {!loading ? (
                bankData.map((bank: any, index) => (
                  <Box
                    maxWidth="70px"
                    p={2}
                    sx={{
                      backgroundColor: tab == index ? "lightgray" : "",
                      "&:hover": {
                        backgroundColor: tab == index ? "gray" : "lightgray",
                      },
                    }}
                    onClick={() => {
                      setTab(index);
                      setBankId(bank.id);
                      setSelected(true);
                    }}
                  >
                    <Box
                      component="img"
                      height="70px"
                      width="70px"
                      src={bank.url}
                      alt={bank.name}
                    />
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      textAlign="center"
                      sx={{ flexWrap: "wrap" }}
                    >
                      {bank.name}
                    </Typography>
                  </Box>
                ))
              ) : (
                <CircularProgress
                  size={36}
                  sx={{ color: "#D3114C", margin: "0 auto", py: 2 }}
                />
              )}
              {/* <Button
                variant="contained"
                fullWidth
                sx={{ my: 2, textTransform: "none" }}
                disabled={bankId == ""}
                onClick={() => {
                  setSelected(true);
                }}
              >
                {t("bank.button1")}
              </Button> */}
            </Box>
          ) : (
            <Box mt={4}>
              <form onSubmit={handleSubmit(addBankInfo)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      type="number"
                      size="small"
                      //   id="outlined-basic"
                      variant="outlined"
                      placeholder={t("bank.ph1")}
                      {...register("account_number", {
                        required: t("profile.err1"),
                        pattern: {
                          value: /^\d{10}$/,
                          message: t("profile.err3"),
                        },
                      })}
                      sx={{
                        borderRadius: "0.50rem",
                        "& input": {
                          textAlign: "center", // Apply text-align to the input element
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    <Typography variant="caption" sx={{ color: "red" }}>
                      {/*  @ts-ignore: Unreachable code error */}
                      {errors?.account_number?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      //   id="outlined-basic"
                      variant="outlined"
                      placeholder={t("bank.ph2")}
                      {...register("name", {
                        required: true,
                        maxLength: 20,
                      })}
                      sx={{
                        borderRadius: "0.50rem",
                        "& input": {
                          textAlign: "center", // Apply text-align to the input element
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    {errors.name && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err1")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      //   id="outlined-basic"
                      variant="outlined"
                      placeholder={t("bank.ph3")}
                      {...register("surname", {
                        required: true,
                        maxLength: 20,
                      })}
                      sx={{
                        borderRadius: "0.50rem",
                        "& input": {
                          textAlign: "center", // Apply text-align to the input element
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    {errors.surname && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err1")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Typography fontWeight={600} textAlign="center" my={2}>
                  {t("bank.title3")}
                </Typography>

                <Button
                  variant="contained"
                  fullWidth
                  sx={{ my: 2, textTransform: "none" }}
                  disabled={tab == -1}
                  type="submit"
                >
                  {loading2 ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    t("bank.button3")
                  )}
                </Button>
                <Typography
                  textAlign="center"
                  variant="body2"
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  color="gray"
                  onClick={() => {
                    setSelected(false);
                  }}
                >
                  {"<"} {t("bank.button2")}
                </Typography>
              </form>
            </Box>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default BankPage;
