import { Card, CardContent, Box, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeFilledRoundedIcon from "@mui/icons-material/AccessTimeFilledRounded";
import { useTranslation } from "react-i18next";

const TranscationCard = ({
  isDeposite = true,
  depositAmount = 200,
  addedAmount = 50,
  status = "loading...",
  date = "2023-09-18T06:09:08.000Z",
}) => {
  const { t, i18n } = useTranslation();

  function formatDate(dateString: any) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = date.getHours() >= 12 ? "pm" : "am";

    return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;
  }
  const newDate = formatDate(date);
  return (
    <Card variant="outlined" sx={{ borderRadius: "0.75rem", pb: "0", my: 0.5 }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: "16px !important",
        }}
      >
        <Box>
          <Typography variant="body2" color="textSecondary">
            {isDeposite
              ? t("wallet.transcation.title1")
              : t("wallet.transcation.title4")}
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            component="span"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {t("wallet.transcation.title2")}
            {": "}
            {status == "Approved" ? (
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  color: "black",
                }}
              >
                <CheckCircleIcon
                  sx={{
                    color: "green",
                    fontSize: "1.25rem",
                  }}
                />
                {t("wallet.transcation.title3")}
              </Typography>
            ) : status == "Rejected" ? (
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  color: "black",
                }}
              >
                <CancelIcon
                  sx={{
                    color: "red",
                    fontSize: "1.25rem",
                  }}
                />
                {t("wallet.transcation.title6")}
              </Typography>
            ) : (
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  color: "black",
                }}
              >
                <AccessTimeFilledRoundedIcon
                  sx={{
                    color: "blue",
                    fontSize: "1.25rem",
                  }}
                />
                {t("wallet.transcation.title5")}
              </Typography>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {isDeposite ? (
            <>
              <Typography variant="h6" component="span" sx={{ color: "green" }}>
                {/* {depositAmount} */}+{addedAmount}
              </Typography>
              <Typography variant="body2" color="textSecondary" ml={2}>
                {newDate}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" component="span" sx={{ color: "Red" }}>
                {/* {depositAmount} */}-{addedAmount}
              </Typography>
              <Typography variant="body2" color="textSecondary" ml={2}>
                {newDate}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TranscationCard;
