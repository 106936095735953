import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import selectLogo from "../../assets/images/select_logo.png";
import wallet from "../../assets/images/wallet.png";
import history from "../../assets/images/history.png";
import promotion from "../../assets/images/promotion.png";
import contactUs from "../../assets/images/contactUs.png";
import { Navigate, useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import LogoutIcon from "@mui/icons-material/Logout";
import category1 from "../../assets/images/category1.gif";
import category2 from "../../assets/images/category2.gif";
import category3 from "../../assets/images/category3.gif";
import category4 from "../../assets/images/category4.gif";
import category5 from "../../assets/images/category5.gif";
import category6 from "../../assets/images/category6.gif";
import ImageData from "../../components/ImageData";
import Slider from "react-slick";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import WalletCommon from "../../components/common/WalletCommon";
import Contact from "../../components/common/Contact";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios, { GameStrapi, getHeaders } from "../../config/axios";
import InnerLoader from "../../components/common/Loader/InnerLoader";

const Dashboard = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall2 = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const [popularGame, setPopularGame] = useState<any>([]);
  const [pSlots, setpSlots] = useState<any>([]);
  const [pCasino, setpCasino] = useState<any>([]);
  const [pSports, setpSports] = useState<any>([]);
  const skeletonArray = ["", "", "", "", "", ""];
  const client = useAxiosPrivate(axios);

  const temp = localStorage.getItem("token");
  const tempToken = temp ? JSON.parse(temp) : "";

  // const tokenStr: any = localStorage.getItem("token") || null;
  // let header: any = tokenStr ? JSON.parse(tokenStr) : null;
  // const [strapiHeader, setStrapiHeader] = useState(header);
  const [strapiHeader, setStrapiHeader] = useState<any>(null);

  const handleClose = () => {
    setOpen(false);
  };

  //strapi clinet
  const strapiClient = useAxiosPrivate(GameStrapi);

  const settings2: any = {
    dots: false,
    infinite: true,
    speed: 700,
    centerMode: true,
    slidesToShow: isSmall ? (isSmall2 ? 1.8 : 3) : 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    rtl: true,
  };

  const getTopSlots = async (header: any) => {
    try {
      const response = await strapiClient.get(API_ROUTES.Game_Slots, header);
      const data = response.data.data[0].attributes.games.data;
      const tempList: any = [];
      data.map((ele: any) => {
        const url = ele?.attributes.url.data?.[0]?.attributes.url;
        const id = ele?.id;
        tempList.push({ url: `https://uat-strapi.pugs.team${url}`, id: id });
      });
      setpSlots(tempList);
    } catch (error) {
      console.log(error);
    }
  };
  const getTopCasino = async (header: any) => {
    try {
      const response = await strapiClient.get(API_ROUTES.Game_Casino, header);
      const data = response.data.data[0].attributes.games.data;
      const tempList: any = [];
      data.map((ele: any) => {
        const url = ele?.attributes.url.data?.[0]?.attributes.url;
        const id = ele?.id;
        tempList.push({ url: `https://uat-strapi.pugs.team${url}`, id: id });
      });
      setpCasino(tempList);
    } catch (error) {
      console.log(error);
    }
  };
  const getTopSport = async (header: any) => {
    try {
      const response = await strapiClient.get(API_ROUTES.Game_Sports, header);
      const data = response.data.data[0].attributes.games.data;
      const tempList: any = [];
      data.map((ele: any) => {
        const url = ele?.attributes.url.data?.[0]?.attributes.url;
        const id = ele?.id;
        tempList.push({ url: `https://uat-strapi.pugs.team${url}`, id: id });
      });
      setpSports(tempList);
    } catch (error) {
      console.log(error);
    }
  };
  const getPopularGames = async (header: any) => {
    try {
      const response = await strapiClient.get(API_ROUTES.Game_Popular, header);
      console.log("getPopularGames ::", response);
      const data = response.data.data[0].attributes.games.data;
      const tempList: any = [];
      data.map((ele: any) => {
        const url = ele?.attributes.url.data?.[0]?.attributes.url;
        const id = ele?.id;
        tempList.push({ url: `https://uat-strapi.pugs.team${url}`, id: id });
      });
      setPopularGame(tempList);
    } catch (error) {
      console.log("getPopularGames ERROR::", error);
    }
  };

  const GetHeader = async () => {
    try {
      const res = await client.get(API_ROUTES.Get_header);
      const token = res.data;
      localStorage.setItem("token", JSON.stringify(token));
      setStrapiHeader(token);
    } catch (error) {}
  };

  useEffect(() => {
    if (!tempToken) {
      GetHeader();
    } else {
      setStrapiHeader(tempToken);
    }
  }, []);

  useEffect(() => {
    console.log("strapiHeader ::", strapiHeader);
    if (strapiHeader) {
      getPopularGames(strapiHeader);
      getTopSlots(strapiHeader);
      getTopCasino(strapiHeader);
      getTopSport(strapiHeader);
    }
  }, [strapiHeader]);
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <WalletCommon />
        <Grid container spacing={1.5} mt={0.5}>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                background: "white",
                borderRadius: "0.50rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(APP_ROUTES.Wallet)}
            >
              <Avatar src={wallet} sx={{ mb: 1 }} />
              <Typography
                color="#0b55ad"
                variant="body2"
                sx={{ fontWeight: "600", textAlign: "center" }}
              >
                {t("dashboard.cards.wallet")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                background: "white",
                borderRadius: "0.50rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(APP_ROUTES.History)}
            >
              <Avatar src={history} sx={{ mb: 1 }} />
              <Typography
                color="#0b55ad"
                variant="body2"
                sx={{
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                {t("dashboard.cards.history")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                background: "white",
                borderRadius: "0.50rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(APP_ROUTES.Promotion)}
            >
              <Avatar src={promotion} sx={{ mb: 1 }} />
              <Typography
                color="#0b55ad"
                variant="body2"
                sx={{ fontWeight: "600", textAlign: "center" }}
              >
                {t("dashboard.cards.promotion")}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
                background: "white",
                borderRadius: "0.50rem",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Avatar src={contactUs} sx={{ mb: 1 }} />
              <Typography
                variant="body2"
                color="#0b55ad"
                sx={{ fontWeight: "600", textAlign: "center" }}
              >
                {t("dashboard.cards.contact")}
              </Typography>
            </Box>
            <Contact open={open} handleClose={handleClose} />
          </Grid>
        </Grid>
        <Grid container spacing={1} my={2}>
          <Grid item xs={6} sm={4}>
            <Card
              sx={{
                borderRadius: "1.75rem",
                backgroundColor: "#f1f5f9",
                cursor: "pointer",
              }}
            >
              <CardMedia component="img" image={category1} title="lotto" />
            </Card>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Card
              sx={{
                borderRadius: "1.75rem",
                backgroundColor: "#f1f5f9",
                cursor: "pointer",
              }}
            >
              <CardMedia component="img" image={category2} title="game-list" />
            </Card>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Card
              sx={{
                borderRadius: "1.75rem",
                backgroundColor: "#f1f5f9",
                cursor: "pointer",
              }}
            >
              <CardMedia
                component="img"
                image={category3}
                title="slot"
                onClick={() => {
                  navigate(APP_ROUTES.Slots);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Card
              sx={{
                borderRadius: "1.75rem",
                backgroundColor: "#f1f5f9",
                cursor: "pointer",
              }}
            >
              <CardMedia
                component="img"
                image={category4}
                title="casino"
                onClick={() => {
                  navigate(APP_ROUTES.Casino);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Card
              sx={{
                borderRadius: "1.75rem",
                backgroundColor: "#f1f5f9",
                cursor: "pointer",
              }}
            >
              <CardMedia
                component="img"
                image={category5}
                title="sport"
                onClick={() => {
                  navigate(APP_ROUTES.Sports);
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Card
              sx={{
                borderRadius: "1.75rem",
                backgroundColor: "#f1f5f9",
                cursor: "pointer",
              }}
            >
              <CardMedia component="img" image={category6} title="fish" />
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 2 }}>
            <img src={selectLogo} height="30px" width="30px" />
            <Typography
              variant="h6"
              color="#D3114C"
              sx={{ fontWeight: "600", ml: 1 }}
            >
              {t("dashboard.category1")}
            </Typography>
            <Typography
              variant="h6"
              color="#0b55ad"
              sx={{
                fontWeight: "600",
                ml: 1,
                margin: "0 12px 0 auto",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  APP_ROUTES.PopularGames.replace(":name", "PopularGames")
                );
              }}
            >
              {t("common.all")}
            </Typography>
          </Box>
          <Slider {...settings2}>
            {popularGame.length > 0
              ? popularGame.map((ele: any) => (
                  <Box>
                    <img
                      src={ele.url}
                      onClick={() =>
                        navigate(APP_ROUTES.PlayGame.replace(":id", ele.id))
                      }
                      style={{
                        maxWidth: "160px",
                        width: "90%",
                        height: "200px",
                        objectFit: "fill",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))
              : skeletonArray.map((ele: any) => (
                  <Box sx={{ px: 1 }}>
                    <Skeleton variant="rounded" width="90%" height={200} />
                  </Box>
                ))}
          </Slider>
        </Box>
        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 2 }}>
            <img src={selectLogo} height="30px" width="30px" />
            <Typography
              variant="h6"
              color="#D3114C"
              sx={{ fontWeight: "600", ml: 1 }}
            >
              {t("dashboard.category2")}
            </Typography>
            <Typography
              variant="h6"
              color="#0b55ad"
              sx={{
                fontWeight: "600",
                ml: 1,
                margin: "0 12px 0 auto",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  APP_ROUTES.PopularGames.replace(":name", "PopularSlots")
                );
              }}
            >
              {t("common.all")}
            </Typography>
          </Box>
          <Slider {...settings2}>
            {pSlots.length > 0
              ? pSlots &&
                pSlots.map((ele: any) => (
                  <Box>
                    <img
                      src={ele.url}
                      onClick={() =>
                        navigate(APP_ROUTES.PlayGame.replace(":id", ele.id))
                      }
                      style={{
                        width: "90%",
                        height: "200px",
                        objectFit: "fill",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))
              : skeletonArray.map((ele: any) => (
                  <Box sx={{ px: 1 }}>
                    <Skeleton variant="rounded" width="90%" height={200} />
                  </Box>
                ))}
          </Slider>
        </Box>
        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 2 }}>
            <img src={selectLogo} height="30px" width="30px" />
            <Typography
              variant="h6"
              color="#D3114C"
              sx={{ fontWeight: "600", ml: 1 }}
            >
              {t("dashboard.category3")}
            </Typography>
            <Typography
              variant="h6"
              color="#0b55ad"
              sx={{
                fontWeight: "600",
                ml: 1,
                margin: "0 12px 0 auto",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(
                  APP_ROUTES.PopularGames.replace(":name", "PopularCasino")
                )
              }
            >
              {t("common.all")}
            </Typography>
          </Box>
          <Slider {...settings2}>
            {pCasino.length > 0
              ? pCasino.map((ele: any) => (
                  <Box>
                    <img
                      src={ele.url}
                      onClick={() =>
                        navigate(APP_ROUTES.PlayGame.replace(":id", ele.id))
                      }
                      style={{
                        width: "90%",
                        height: "200px",
                        objectFit: "fill",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))
              : skeletonArray.map((ele: any) => (
                  <Box sx={{ px: 1 }}>
                    <Skeleton variant="rounded" width="90%" height={200} />
                  </Box>
                ))}
          </Slider>
        </Box>
        <Box sx={{ my: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", mt: 3, mb: 2 }}>
            <img src={selectLogo} height="30px" width="30px" />
            <Typography
              variant="h6"
              color="#D3114C"
              sx={{ fontWeight: "600", ml: 1 }}
            >
              {t("dashboard.category4")}
            </Typography>
            <Typography
              variant="h6"
              color="#0b55ad"
              sx={{
                fontWeight: "600",
                ml: 1,
                margin: "0 12px 0 auto",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(
                  APP_ROUTES.PopularGames.replace(":name", "PopularSports")
                );
              }}
            >
              {t("common.all")}
            </Typography>
          </Box>
          <Slider {...settings2}>
            {pSports.length > 0
              ? pSports.map((ele: any) => (
                  <Box>
                    <img
                      src={ele.url}
                      onClick={() =>
                        navigate(APP_ROUTES.PlayGame.replace(":id", ele.id))
                      }
                      style={{
                        maxWidth: "160px",
                        width: "90%",
                        height: "200px",
                        objectFit: "fill",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                ))
              : skeletonArray.map((ele: any) => (
                  <Box sx={{ px: 1 }}>
                    <Skeleton variant="rounded" width="90%" height={200} />
                  </Box>
                ))}
          </Slider>
        </Box>
      </Container>
    </Box>
  );
};

export default Dashboard;
