import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function RulesPopUp(props: any) {
  const { openRule, handleCloseRule } = props;
  const { t, i18n } = useTranslation();

  return (
    <Box sx={{}}>
      <Dialog
        open={openRule}
        onClose={handleCloseRule}
        sx={{
          p: 0,
          ".MuiDialog-paper": {
            borderRadius: "0.75rem",
            maxWidth: "350px",
            minWidth: "325px",
          },
        }}
      >
        <DialogTitle sx={{ color: "white" }} align="center"></DialogTitle>
        <DialogContent
          sx={{ height: "600px", overflowY: "scroll", margin: "0 auto" }}
        >
          <Typography variant="h6" color="#D3114C">
            {t("landing.rulespop.title1")}
          </Typography>
          {t("landing.rulespop.disc1")}
          <Typography variant="h6" color="#D3114C">
            {t("landing.rulespop.title2")}
          </Typography>
          {t("landing.rulespop.disc2")}
          <Typography variant="h6" color="#D3114C">
            {t("landing.rulespop.title3")}
          </Typography>
          {t("landing.rulespop.disc3")}
          <Typography variant="h6" color="#D3114C">
            {t("landing.rulespop.title4")}
          </Typography>
          {t("landing.rulespop.disc4")}
          <Typography variant="h6" color="#D3114C">
            {t("landing.rulespop.title5")}
          </Typography>
          {t("landing.rulespop.disc5")}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={handleCloseRule}
            sx={{
              width: "90%",
              margin: "0 auto",
              background: "#272a40",
              "&:hover": { background: "#272a40" },
            }}
          >
            Close window
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default RulesPopUp;
