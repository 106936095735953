import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Container,
  Divider,
  createTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import KeyIcon from "@mui/icons-material/Key";
import { useNavigate } from "react-router-dom";
import { emailPasswordSignUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { doesEmailExist } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { createCode } from "supertokens-web-js/recipe/passwordless";
import { consumeCode } from "supertokens-web-js/recipe/passwordless";
import { getLoginAttemptInfo } from "supertokens-web-js/recipe/passwordless";
import { resendCode } from "supertokens-web-js/recipe/passwordless";
import { APP_ROUTES } from "../../utils/constants";
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import LoginPopup from "../../components/common/LoginPopup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const RegisterPage = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [registerData, setRegisterData] = useState({
    password: "",
    username: "",
  });
  const [passwordLess, setPasswordLess] = useState({
    username: "",
    otp: "",
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const handleSignUp = async () => {
    // Perform login logic here, e.g., API calls, authentication, etc.
    if (registerData.username && registerData.password) {
      setPasswordLess({
        username: "",
        otp: "",
      });
      if (await checkEmail(registerData.username)) {
        signUpClicked(registerData.username, registerData.password);
      }
    }
    if (passwordLess.username && passwordLess.otp && isOtpSent) {
      handleOTPInput(passwordLess.otp);
    } else if (passwordLess.username) {
      setRegisterData({
        password: "",
        username: "",
      });
      const check = validateEmailPhoneInput(passwordLess.username);
      if (check === "email") {
        sendOTP(passwordLess.username, null);
      } else if (check === "phone") {
        sendOTP(null, passwordLess.username);
      }
    }

    // Close the dialog after login logic
    // handleClose();
  };

  async function checkEmail(email: string) {
    try {
      setLoading(true);
      let response = await doesEmailExist({
        email,
      });

      if (response.doesExist) {
        toast.error("Email already exists. Please sign in instead");
        return false;
      } else {
        return true;
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
      return false;
    } finally {
      setLoading(false);
    }
  }

  async function signUpClicked(email: string, password: string) {
    try {
      setLoading(true);
      let response = await emailPasswordSignUp({
        formFields: [
          {
            id: "email",
            value: email,
          },
          {
            id: "password",
            value: password,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validaiton
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax),
            // or the email is not unique.
            toast.error(formField.error);
          } else if (formField.id === "password") {
            // Password validation failed.
            // Maybe it didn't match the password strength
            toast.error(formField.error);
          }
        });
      } else {
        // sign up successful. The session tokens are automatically handled by
        // the frontend SDK.
        // window.location.href = "/homepage";
        navigate(APP_ROUTES.BankPage);
        toast.success("Account created successfully.");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (name: string, value: string) => {
    setErrorMsg("");
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const validateEmailPhoneInput = (field: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im;
    let check: any = "invalid";
    if (emailRegex.test(field)) {
      //it's an email address
      check = "email";
    } else if (phoneRegex.test(field)) {
      //it's a phone number
      check = "phone";
    } else {
      //display your message or highlight your field or whatever.
      // field.classList.add("invalid");
      check = "invalid";
      toast.error("Enter valid phone number or email address");
    }
    return check;
  };

  async function sendOTP(email: string | null, phoneNumber: string | null) {
    try {
      if (email) {
        let response = await createCode({
          email,
        });
        // OTP sent successfully.
        toast.success("Please check your email for an OTP");
      } else if (phoneNumber) {
        let response = await createCode({
          phoneNumber,
        });
        toast.success("Please check your message for an OTP");
      }
      setIsOtpSent(true);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  async function handleOTPInput(otp: string) {
    try {
      setLoading(true);
      let response = await consumeCode({
        userInputCode: otp,
      });

      if (response.status === "OK") {
        if (response.createdNewUser) {
          navigate(APP_ROUTES.BankPage);
          // user sign up success
        } else {
          // user sign in success
        }
        // window.location.assign("/home");
      } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
        // the user entered an invalid OTP
        toast.error(
          "Wrong OTP! Please try again. Number of attempts left: " +
            (response.maximumCodeInputAttempts -
              response.failedCodeInputAttemptCount)
        );
      } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        toast.error(
          "Old OTP entered. Please regenerate a new one and try again"
        );
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        toast.error("Login failed. Please try again");
        window.location.assign("/auth");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  }

  async function googleSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        providerId: "google",

        // This is where Google should redirect the user back after login or error.
        // This URL goes on the Google's dashboard as well.
        // authorisationURL: `http://localhost:3000/auth/callback/google`,
        authorisationURL: `${process.env.REACT_APP_WEBSITE_URL}/auth/callback/google`,
      });

      /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */

      console.log("authUrl ::", authUrl);
      // we redirect the user to google for auth.
      window.location.assign(authUrl);
      // navigate(authUrl);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }
  async function hasInitialOTPBeenSent() {
    return (await getLoginAttemptInfo()) !== undefined;
  }

  async function resendOTP() {
    try {
      let response = await resendCode();

      if (response.status === "RESTART_FLOW_ERROR") {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.
        toast.error("Login failed. Please try again");
        navigate("/");
      } else {
        // OTP resent successfully.
        toast.error("Please check your email for the OTP");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  return (
    <Container maxWidth={isSmall ? "sm" : "md"}>
      <Card
        sx={{
          borderRadius: "0.50rem",
          width: isSmall ? "100%" : "90%",
          margin: "0 auto",
          p: 0,
          boxShadow: (theme) =>
            `0 10px 15px -3px ${theme.palette.primary.main}, 0 4px 6px -4px rgba(0,0,0,.1)`,
        }}
      >
        <LoginPopup open={open} handleClose={handleClose} />
        <CardContent sx={{ width: "90%", margin: "0 auto", p: 0 }}>
          <h1 style={{ textAlign: "center" }}>{t("title")}</h1>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              background: "linear-gradient(to left,#2b2f4a,#484d6a)",
              textTransform: "none",
              fontWeight: "500",
            }}
            onClick={handleOpen}
          >
            {t("common.register.button1")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              background: "#72be2c",
              textTransform: "none",
              fontWeight: "500",
              mt: 1.2,
              "&:hover": { background: "#72be2c" },
            }}
          >
            {t("common.register.line")}
          </Button>
          <Button
            onClick={googleSignInClicked}
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              background: "#66cbe7",
              textTransform: "none",
              fontWeight: "500",
              my: 1.2,
              "&:hover": { background: "#66cbe7" },
            }}
          >
            {t("common.register.google")}
          </Button>
          <TextField
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            size="small"
            disabled={!!passwordLess.username}
            value={registerData.username}
            placeholder={t("common.loginpop.email")}
            onChange={(e: any) => handleChange("username", e.target.value)}
            inputProps={{
              sx: {
                background: "white",
                borderRadius: "0.40rem",
                fontWeight: "600",
                "&::placeholder": {
                  color: "#910015",
                  fontWeight: "600",
                },
              },
            }}
          />
          <TextField
            margin="dense"
            type="password"
            fullWidth
            size="small"
            disabled={!!passwordLess.username}
            value={registerData.password}
            placeholder={t("common.loginpop.password")}
            onChange={(e: any) => handleChange("password", e.target.value)}
            inputProps={{
              style: {
                background: "white",
                color: "#910015",
                borderRadius: "0.40rem",
                fontWeight: "600",
              },
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "25%",
              margin: "0 auto",
              my: 1,
            }}
          >
            <Divider sx={{ flexGrow: 1, border: "1px solid #c40a32" }} />
            <Typography variant="body1" color="#c40a32" sx={{ mx: 2 }}>
              {t("common.loginpop.or")}
            </Typography>
            <Divider sx={{ flexGrow: 1, border: "1px solid #c40a32" }} />
          </Box>
          <TextField
            autoFocus
            margin="dense"
            type="text"
            fullWidth
            size="small"
            placeholder={t("common.loginpop.passwordless")}
            value={passwordLess.username}
            onChange={(e: any) =>
              setPasswordLess({ ...passwordLess, username: e.target.value })
            }
            disabled={
              !registerData.username == false ||
              !registerData.password == false ||
              isOtpSent
            }
            inputProps={{
              style: {
                background: "white",
                color: "#910015",
                borderRadius: "0.40rem",
                fontWeight: "600",
              },
            }}
          />
          {isOtpSent && (
            <TextField
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              size="small"
              placeholder="Enter OTP"
              value={passwordLess.otp}
              onChange={(e: any) =>
                setPasswordLess({ ...passwordLess, otp: e.target.value })
              }
              inputProps={{
                sx: {
                  background: "white",
                  borderRadius: "0.40rem",
                  textAlign: "center",
                  "&::placeholder": {
                    fontWeight: "600",
                  },
                },
              }}
            />
          )}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              mt: 1,
              background: "#00976a",
              textTransform: "none",
              fontWeight: "500",
              "&:hover": { background: "#00976a" },
            }}
            onClick={handleSignUp}
          >
            {loading ? ( // Render the loader when loading is true
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              t("common.register.button2")
            )}
          </Button>
          {isOtpSent && (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                mt: 1,
                background: "#00976a",
                textTransform: "none",
                fontWeight: "500",
                "&:hover": { background: "#00976a" },
              }}
              onClick={resendOTP}
            >
              {t("common.register.resend")}
            </Button>
          )}
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              my: 2,
              textDecoration: "underline",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "12px" }} />
            {t("common.register.button3")}
          </Typography>
        </CardContent>
      </Card>
      <Box mt={2} sx={{ width: isSmall ? "100%" : "90%", margin: "20px auto" }}>
        <Typography variant="h6" sx={{ color: "#3b82f6" }}>
          {t("common.register.p_title1")}
        </Typography>
        <Typography variant="body2">{t("common.register.p_disc1")}</Typography>
      </Box>
    </Container>
  );
};

export default RegisterPage;
