import SuperTokens from "supertokens-auth-react";
import ThirdPartyEmailPassword, {
  Google,
  Facebook,
} from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import Session from "supertokens-auth-react/recipe/session";
import { APP_ROUTES } from "../utils/constants";

export function getApiDomain() {
  const apiPort = process.env.REACT_APP_API_PORT || 8000;
  const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
  return apiUrl;
}

export function getWebsiteDomain() {
  const websitePort = process.env.REACT_APP_WEBSITE_PORT || 3000;
  const websiteUrl =
    process.env.REACT_APP_WEBSITE_URL || `http://localhost:${websitePort}`;
  return websiteUrl;
}

console.log("getApiDomain ::", getApiDomain());

export const frontendConfig = () => ({
  appInfo: {
    appName: "SuperTokens Demo App", // TODO: Your app name
    apiDomain: getApiDomain(), // TODO: Change to your app's API domain
    websiteDomain: getWebsiteDomain(), // TODO: Change to your app's website domain
    websiteBasePath: "/login",
  },
  recipeList: [
    // EmailVerification.init({
    //   mode: "OPTIONAL",
    // }),
    ThirdPartyEmailPassword.init({
      signInAndUpFeature: {
        providers: [Google.init(), Facebook.init()],
      },
      getRedirectionURL: async (context) => {
        if (context.action === "SUCCESS") {
          if (context.redirectToPath !== undefined) {
            // we are navigating back to where the user was before they authenticated
            return context.redirectToPath;
          }
          return APP_ROUTES.Home;
        }
        return undefined;
      },
    }),
    Passwordless.init({
      contactMethod: "EMAIL_OR_PHONE",
    }),
    Session.init({
      //   tokenTransferMethod: "header",
      tokenTransferMethod: "header",
    }),
  ],
});
