import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import axios from "../../config/axios";
import { getApiDomain } from "../../config/frontendConfig";
import Loader from "../../components/common/Loader/Loader";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { API_ROUTES } from "../../utils/constants";

const Profile2 = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [profileData, setProfileData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const ageLimitDate = moment().subtract(18, "years");
  const { t, i18n } = useTranslation();
  const [userData, setUserData] = useState<any>({
    user: "",
    name: "",
    surname: "",
    dob: "",
    number: "",
    email: "",
    line: "",
  });
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const GetProfile = async () => {
    try {
      setIsLoading(true);
      let response = await axios.get(API_ROUTES.Get_Profile);
      console.log("response ::", response);
      setProfileData(response?.data?.data);

      setValue("firstName", response?.data?.data?.userInfo?.firstName);
      setValue("lastName", response?.data?.data?.userInfo?.lastName);
      setValue("userName", response?.data?.data?.userInfo?.userName);
      setValue(
        "dob",
        response?.data?.data?.userInfo?.dob
          ? moment(response?.data?.data?.userInfo?.dob).format("YYYY-MM-DD")
          : null
      );
      setValue("phoneNumber", response?.data?.data?.userInfo?.mobile);
      setValue("rankName", response?.data?.data?.userInfo?.ranking?.name || "");
      //   setValue("lineId", response?.data?.data?.userInfo?.lineId);

      userData["user"] = response?.data?.data?.userInfo?.userName;
      userData["name"] = response?.data?.data?.userInfo?.firstName;
      userData["surname"] = response?.data?.data?.userInfo?.lastName;
      userData["dob"] = response?.data?.data?.userInfo?.dob
        ? moment(response?.data?.data?.userInfo?.dob).format("YYYY-MM-DD")
        : "";
      userData["email"] = response?.data?.data?.userInfo?.email;
      userData["line"] = response?.data?.data?.userInfo?.lineId;
      userData["number"] = response?.data?.data?.userInfo?.mobile;
      setUserData(userData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    const updatedData = {
      firstName: data.firstName,
      lastName: data.lastName,
      userName: data.userName,
      mobile: data.phoneNumber,
      dob: data.dob,
    };

    try {
      setIsSubmitLoading(true);
      let response = await axios.post(API_ROUTES.Update_Profile, updatedData);
      console.log("response ::", response);
      toast.success("Profile updated successfully.");
      GetProfile();
    } catch (error: any) {
      if (error.response) toast.error(error.response.data.message);
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    GetProfile();
  }, []);

  console.log("errors ::", errors);

  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        {!isLoading ? (
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "0.75rem",
              p: 2,
              pb: 4,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                py: 2,
                fontWeight: "600",
                fontSize: "20px",
                color: "#D3114C",
              }}
            >
              {t("profile.titlemain")}
            </Typography>
            <Box sx={{ width: "99%", margin: "0 auto" }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {t("profile.title1")}
                    </Typography>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      value={userData.user}
                      fullWidth
                      disabled={userData.user}
                      placeholder={t("profile.ph1")}
                      {...register("userName", {
                        required: true,
                        maxLength: 20,
                      })}
                      sx={{
                        backgroundColor: userData.user ? "#EDEDEE" : "",
                        borderRadius: "0.50rem",
                        "& input": {
                          textAlign: "center", // Apply text-align to the input element
                        },
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    {errors.userName && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err1")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t("profile.title2")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      //   id="outlined-basic"
                      variant="outlined"
                      placeholder={t("profile.ph2")}
                      {...register("firstName", {
                        required: true,
                        maxLength: 20,
                      })}
                      sx={{
                        borderRadius: "0.50rem",
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    {errors.firstName && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err1")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t("profile.title3")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      placeholder={t("profile.ph3")}
                      {...register("lastName", {
                        required: true,
                        pattern: /^[A-Za-z0-9]+$/i,
                      })}
                      sx={{
                        borderRadius: "0.50rem",
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    {errors.lastName && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err1")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t("profile.title4")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      type="date"
                      placeholder={t("profile.ph4")}
                      {...register("dob", {
                        required: true,
                        max: ageLimitDate.format("YYYY-MM-DD"),
                      })}
                      sx={{
                        borderRadius: "0.50rem",
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                      onChange={(e: any) => {
                        const newDob = e.target.value;
                        setUserData((prevData: any) => ({
                          ...prevData,
                          dob: newDob,
                        }));
                      }}
                    />
                    {errors.dob?.type == "required" && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err1")}
                      </Typography>
                    )}
                    {errors.dob?.type == "max" && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {t("profile.err2")}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t("profile.title5")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      disabled={userData.number}
                      {...register("phoneNumber", {
                        pattern:
                          /^\+?[0-9]?[-. ]?\(?([0-9]{1,4})\)?[-. ]?[0-9]{1,5}[-. ]?[0-9]{1,5}$/,
                        required: true,
                      })}
                      placeholder={t("profile.ph5")}
                      sx={{
                        backgroundColor: userData.number ? "#EDEDEE" : "",
                        borderRadius: "0.50rem",
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                    />
                    {errors.phoneNumber && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        "This field is required"
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t("profile.title6")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      {...register("Email", {
                        maxLength: 20,
                      })}
                      value={userData.email}
                      disabled={userData.email}
                      placeholder={t("profile.ph6")}
                      sx={{
                        backgroundColor: userData.email ? "#EDEDEE" : "",
                        borderRadius: "0.50rem",
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                      onChange={(e: any) => {
                        const newEmail = e.target.value;
                        setUserData((prevData: any) => ({
                          ...prevData,
                          email: newEmail,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t("profile.title7")}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      variant="outlined"
                      value={userData?.line}
                      placeholder={t("profile.ph7")}
                      disabled
                      //   value={userData.line}
                      //   {...register("lineId", {
                      //     required: true,
                      //     maxLength: 20,
                      //   })}
                      sx={{
                        backgroundColor: "#EDEDEE",
                        borderRadius: "0.50rem",
                      }}
                      InputProps={{
                        sx: {
                          borderRadius: "0.50rem",
                        },
                      }}
                      onChange={(e: any) => {
                        const newLine = e.target.value;
                        setUserData((prevData: any) => ({
                          ...prevData,
                          line: newLine,
                        }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      disabled={isSubmitLoading}
                      sx={{
                        backgroundColor: "#00976A",
                        borderRadius: "0.50rem",
                        textTransform: "none",
                        fontSize: "16px",
                        "&:hover": { backgroundColor: "#00976A" },
                      }}
                      //   onClick={onSubmit}
                    >
                      {!isSubmitLoading ? (
                        t("profile.button1")
                      ) : (
                        <CircularProgress size={24} sx={{ color: "white" }} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor: "lightgray",
              borderRadius: "0.75rem",
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Skeleton variant="rounded" width="100%" height="400px" />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Profile2;
