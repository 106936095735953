import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import { useNavigate } from "react-router-dom";
import { emailPasswordSignIn } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { getAuthorisationURLWithQueryParamsAndSetState } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { APP_ROUTES } from "../../../utils/constants";
import {
  consumeCode,
  createCode,
  resendCode,
} from "supertokens-auth-react/recipe/passwordless";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const LoginPopup = (props: any) => {
  const { open, handleClose } = props;
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [passwordLess, setPasswordLess] = useState({
    username: "",
    otp: "",
  });
  const { t, i18n } = useTranslation();
  const handleLogin = () => {
    // Perform login logic here, e.g., API calls, authentication, etc.
    console.log("Username:", username);
    console.log("Password:", password);
    if (!username && !password && passwordLess.username) {
      const check = validateEmailPhoneInput(passwordLess.username);
      if (check === "email") {
        sendOTP(passwordLess.username, null);
      } else if (check === "phone") {
        sendOTP(null, passwordLess.username);
      }
    } else {
      signInClicked(username, password);
    }

    // Close the dialog after login logic
    // handleClose();
  };
  const navigate = useNavigate();

  async function signInClicked(email: string, password: string) {
    try {
      setLoading(true);
      let response = await emailPasswordSignIn({
        formFields: [
          {
            id: "email",
            value: email,
          },
          {
            id: "password",
            value: password,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            // setErrorMsg(formField.error);
            toast.error(formField?.error);
            // window.alert(formField.error);
          }
        });
      } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
        // setErrorMsg("Email password combination is incorrect.");
        toast.error("Email password combination is incorrect.");
        // window.alert("Email password combination is incorrect.");
      } else {
        // sign in successful. The session tokens are automatically handled by
        // the frontend SDK.
        // window.location.href = "/homepage";
        // setIsOtpSent(true);
        navigate(APP_ROUTES.BankPage);
        toast.success("Successful Login");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // setErrorMsg(err.message);
        // this may be a custom error message sent from the API by you.
        toast.error(err?.message);
        // window.alert(err.message);
      } else {
        // window.alert("Oops! Something went wrong.");
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  }

  async function googleSignInClicked() {
    try {
      const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
        providerId: "google",

        // This is where Google should redirect the user back after login or error.
        // This URL goes on the Google's dashboard as well.
        // authorisationURL: `http://localhost:3000/auth/callback/google`,
        authorisationURL: `${process.env.REACT_APP_WEBSITE_URL}/auth/callback/google`,
      });

      /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */

      console.log("authUrl ::", authUrl);
      // we redirect the user to google for auth.
      window.location.assign(authUrl);
      // navigate(authUrl);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  async function sendOTP(email: string | null, phoneNumber: string | null) {
    try {
      if (email) {
        let response = await createCode({
          email,
        });
        // OTP sent successfully.
        toast.success("Please check your email for an OTP");
      } else if (phoneNumber) {
        let response = await createCode({
          phoneNumber,
        });
        toast.success("Please check your message for an OTP");
      }
      setIsOtpSent(true);
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  async function handleOTPInput() {
    const otp = passwordLess.otp;
    try {
      setLoading(true);
      let response = await consumeCode({
        userInputCode: otp,
      });

      if (response.status === "OK") {
        if (response.createdNewUser) {
          // user sign up success
          navigate(APP_ROUTES.BankPage);
        } else {
          // user sign in success
        }
        // window.location.assign("/home");
        navigate(APP_ROUTES.Home);
      } else if (response.status === "INCORRECT_USER_INPUT_CODE_ERROR") {
        // the user entered an invalid OTP
        toast.error(
          "Wrong OTP! Please try again. Number of attempts left: " +
            (response.maximumCodeInputAttempts -
              response.failedCodeInputAttemptCount)
        );
      } else if (response.status === "EXPIRED_USER_INPUT_CODE_ERROR") {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        toast.error(
          "Old OTP entered. Please regenerate a new one and try again"
        );
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        toast.error("Login failed. Please try again");
        window.location.assign("/auth");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  }

  const validateEmailPhoneInput = (field: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im;
    let check: any = "invalid";
    if (emailRegex.test(field)) {
      //it's an email address
      check = "email";
    } else if (phoneRegex.test(field)) {
      //it's a phone number
      check = "phone";
    } else {
      //display your message or highlight your field or whatever.
      // field.classList.add("invalid");
      check = "invalid";
      toast.error("Enter valid phone number or email address");
    }
    return check;
  };

  async function resendOTP() {
    try {
      setLoading1(true);
      let response = await resendCode();

      if (response.status === "RESTART_FLOW_ERROR") {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.
        toast.error("Login failed. Please try again");
        navigate("/");
      } else {
        // OTP resent successfully.
        toast.success("Please check your email for the OTP");
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading1(false);
    }
  }

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "80%",
          p: 0,
          margin: "0 auto",
          ".MuiDialog-paper": {
            background: "linear-gradient(to top, #910015,#c63131, #e20f42)",
            border: "1px solid rgb(237 153 153/1)",
            borderRadius: "0.75rem",
            maxWidth: "350px",
            minWidth: "325px",
          },
        }}
      >
        <DialogTitle sx={{ color: "white" }} align="center">
          <h1>{t("title")}</h1>
        </DialogTitle>
        {!isOtpSent ? (
          <DialogContent sx={{ width: "80%", margin: "0 auto", p: 0 }}>
            {/* <DialogContentText>
              Please enter your credentials to log in.
            </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              type="email"
              fullWidth
              size="small"
              value={username}
              placeholder={t("common.loginpop.email")}
              onChange={(e: any) => setUsername(e.target.value)}
              disabled={!!passwordLess.username}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              inputProps={{
                sx: {
                  background: "white",
                  borderRadius: "0.40rem",
                  color: "#910015",
                  fontWeight: "600",
                  "&::placeholder": {
                    color: "#910015",
                    fontWeight: "600",
                  },
                },
              }}
            />
            <TextField
              margin="dense"
              type="password"
              fullWidth
              size="small"
              value={password}
              placeholder={t("common.loginpop.password")}
              onChange={(e: any) => setPassword(e.target.value)}
              disabled={!!passwordLess.username}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              inputProps={{
                style: {
                  background: "white",
                  color: "#910015",
                  borderRadius: "0.40rem",
                  fontWeight: "600",
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "50%",
                margin: "0 auto",
                mt: 1,
              }}
            >
              <Divider sx={{ flexGrow: 1, border: "1px solid white" }} />
              <Typography variant="body1" color="white" sx={{ mx: 1 }}>
                {t("common.loginpop.or")}
              </Typography>
              <Divider sx={{ flexGrow: 1, border: "1px solid white" }} />
            </Box>
            <TextField
              margin="dense"
              type="text"
              fullWidth
              size="small"
              placeholder={t("common.loginpop.passwordless")}
              value={passwordLess.username}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              onChange={(e: any) =>
                setPasswordLess({ ...passwordLess, username: e.target.value })
              }
              disabled={!username == false || !password == false}
              inputProps={{
                sx: {
                  background: "white",
                  borderRadius: "0.40rem",
                  color: "#910015",
                  fontWeight: "600",
                  "&::placeholder": {
                    color: "#910015",
                    fontWeight: "600",
                  },
                },
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                mb: 1,
              }}
            >
              <Typography
                variant="caption"
                color="white"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: 0.4,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate(APP_ROUTES.Forget);
                }}
              >
                <KeyIcon sx={{ color: "white", mr: 1 }} />
                {t("common.loginpop.forgotPassword")}
              </Typography>
            </Box>
            <Button
              onClick={handleLogin}
              color="primary"
              variant="contained"
              fullWidth
              disabled={loading ? true : false}
              sx={{
                background: "linear-gradient(to left,#2b2f4a,#484d6a)",
                textTransform: "none",
                fontWeight: "500",
              }}
            >
              {loading ? ( // Render the loader when loading is true
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                t("common.login")
              )}
            </Button>
            <Button
              onClick={handleLogin}
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                background: "#72be2c",
                textTransform: "none",
                fontWeight: "500",
                mt: 1.2,
                "&:hover": { background: "#72be2c" },
              }}
            >
              {t("common.loginpop.line")}
            </Button>
            <Button
              onClick={googleSignInClicked}
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                background: "#66cbe7",
                textTransform: "none",
                fontWeight: "500",
                my: 1.2,
                "&:hover": { background: "#66cbe7" },
              }}
            >
              {t("common.loginpop.google")}
            </Button>
            <Button
              onClick={() => navigate("/register")}
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                background: "0",
                border: "1px solid #72be2c",
                textTransform: "none",
                fontWeight: "500",
                "&:hover": { background: "0" },
              }}
            >
              {t("common.registercommon")}
            </Button>
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                my: 2,
                color: "white",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              {t("common.closeWindow")}
            </Typography>
          </DialogContent>
        ) : (
          <DialogContent sx={{ width: "80%", margin: "0 auto", p: 0 }}>
            <Typography variant="body1" color="white">
              {t("common.loginpop.title1")}
            </Typography>
            <TextField
              margin="dense"
              type="text"
              fullWidth
              size="small"
              placeholder={t("common.loginpop.title1")}
              value={passwordLess.otp}
              onChange={(e: any) =>
                setPasswordLess({ ...passwordLess, otp: e.target.value })
              }
              sx={{
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              inputProps={{
                style: {
                  background: "white",
                  color: "#910015",
                  borderRadius: "0.40rem",
                  fontWeight: "600",
                },
              }}
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleOTPInput}
              sx={{
                background: "linear-gradient(to left,#2b2f4a,#484d6a)",
                textTransform: "none",
                fontWeight: "500",
                my: 1.2,
              }}
            >
              {loading ? ( // Render the loader when loading is true
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                t("common.register.verify")
              )}
            </Button>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              sx={{
                background: "#72be2c",
                textTransform: "none",
                fontWeight: "500",
                mb: 2.2,
                "&:hover": { background: "#72be2c" },
              }}
              onClick={resendOTP}
            >
              {loading1 ? ( // Render the loader when loading is true
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                t("common.register.resend")
              )}
            </Button>
            <Typography
              textAlign="center"
              variant="body2"
              sx={{
                color: "white",
                cursor: "pointer",
                "&:hover": { textDecoration: "underline" },
                mb: 3,
              }}
              color="gray"
              onClick={() => {
                setIsOtpSent(false);
              }}
            >
              {"<"} {t("bank.button2")}
            </Typography>
          </DialogContent>
        )}
      </Dialog>
    </Box>
  );
};
export default LoginPopup;
