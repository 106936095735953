import { Avatar, Box, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import icon1 from "../../../assets/images/innerf1.png";
import acticon1 from "../../../assets/images/actinnerf1.png";
import icon2 from "../../../assets/images/innerf2.png";
import acticon2 from "../../../assets/images/actinnerf2.png";
import icon3 from "../../../assets/images/innerf3.png";
import acticon3 from "../../../assets/images/actinnerf3.png";
import icon4 from "../../../assets/images/innerf4.png";
import acticon4 from "../../../assets/images/actinnerf4.png";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/constants";
import { useTranslation } from "react-i18next";

const InnerFooter = (props: any) => {
  const { pathname } = props;
  const { t, i18n } = useTranslation();
  const session: any = useSessionContext();
  const navigate = useNavigate();

  return (
    <>
      {session?.userId && (
        <>
          <Box marginTop="80px"></Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              background: "white",
              height: "80px",
              justifyContent: "space-between",
              position: "fixed",
              bottom: "0px",

              zIndex: "999px",
            }}
          >
            <Container
              maxWidth="md"
              sx={{
                display: "flex",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: { xs: "space-between", sm: "space-evenly" },
              }}
            >
              <Box
                sx={{
                  px: { xs: 2, sm: 3 },
                  py: 1,
                  "&:hover": {
                    background: "lightgray",
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  navigate(APP_ROUTES.Dashboard);
                }}
              >
                <Avatar
                  src={pathname == "/dashboard" ? acticon1 : icon1}
                  sx={{
                    margin: "auto",
                  }}
                />
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontWeight={600}
                  sx={{
                    whiteSpace: "nowrap",
                    color: pathname == "/dashboard" ? "#D3114C" : "",
                  }}
                >
                  {t("innerFotter.main")}
                </Typography>
              </Box>
              <Box
                sx={{
                  px: { xs: 2, sm: 3 },
                  py: 1,
                  "&:hover": {
                    background: "lightgray",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(APP_ROUTES.Casino)}
              >
                <Avatar
                  src={pathname == "/casino" ? acticon2 : icon2}
                  sx={{ margin: "auto" }}
                />
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontWeight={600}
                  sx={{ color: pathname == "/casino" ? "#D3114C" : "" }}
                >
                  {t("innerFotter.casino")}
                </Typography>
              </Box>
              <Box
                sx={{
                  px: { xs: 2, sm: 3 },
                  py: 1,
                  "&:hover": {
                    background: "lightgray",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(APP_ROUTES.Slots)}
              >
                <Avatar
                  src={pathname == "/slots" ? acticon3 : icon3}
                  sx={{ margin: "auto" }}
                />
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontWeight={600}
                  sx={{ color: pathname == "/slots" ? "#D3114C" : "" }}
                >
                  {t("innerFotter.slots")}
                </Typography>
              </Box>
              <Box
                sx={{
                  px: { xs: 2, sm: 3 },
                  py: 1,
                  "&:hover": {
                    background: "lightgray",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate(APP_ROUTES.Sports)}
              >
                <Avatar
                  src={pathname == "/sports" ? acticon4 : icon4}
                  sx={{ margin: "auto" }}
                />
                <Typography
                  textAlign="center"
                  variant="body2"
                  fontWeight={600}
                  sx={{ color: pathname == "/sports" ? "#D3114C" : "" }}
                >
                  {t("innerFotter.sport")}
                </Typography>
              </Box>
            </Container>
          </Box>
        </>
      )}
    </>
  );
};

export default InnerFooter;
