import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Container,
  createTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";
import { submitNewPassword } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import LoginPopup from "../../components/common/LoginPopup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [registerData, setRegisterData] = useState({
    password: "",
    username: "",
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  const handleChange = (name: string, value: string) => {
    setErrorMsg("");
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  async function newPasswordEntered() {
    try {
      let response = await submitNewPassword({
        formFields: [
          {
            id: "password",
            value: registerData.password,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "password") {
            // New password did not meet password criteria on the backend.

            toast.error(
              formField.error || "New password did not meet password criteria"
            );
          }
        });
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        // the password reset token in the URL is invalid, expired, or already consumed
        toast.error("Password reset failed. Please try again");
        navigate(APP_ROUTES.Landing);
        // window.location.assign("/auth"); // back to the login scree.
      } else {
        toast.success("Password reset successful!");
        navigate(APP_ROUTES.Landing);
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    }
  }

  return (
    <Container maxWidth={isSmall ? "sm" : "md"}>
      <Card
        sx={{
          borderRadius: "0.50rem",
          width: isSmall ? "100%" : "90%",
          margin: "0 auto",
          p: 0,
          boxShadow: (theme) =>
            `0 10px 15px -3px ${theme.palette.primary.main}, 0 4px 6px -4px rgba(0,0,0,.1)`,
        }}
      >
        <LoginPopup open={open} handleClose={handleClose} />
        <CardContent sx={{ width: "90%", margin: "0 auto", p: 0 }}>
          <h1 style={{ textAlign: "center" }}>{t("title")}</h1>
          <TextField
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            size="small"
            value={registerData.password}
            placeholder={t("common.loginpop.password")}
            onChange={(e: any) => handleChange("password", e.target.value)}
            inputProps={{
              sx: {
                background: "white",
                borderRadius: "0.40rem",
                fontWeight: "600",
                "&::placeholder": {
                  color: "#910015",
                  fontWeight: "600",
                },
              },
            }}
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              mt: 1,
              background: "#00976a",
              textTransform: "none",
              fontWeight: "500",
              "&:hover": { background: "#00976a" },
            }}
            onClick={newPasswordEntered}
          >
            {loading ? ( // Render the loader when loading is true
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              t("common.register.button2")
            )}
          </Button>
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              my: 2,
              textDecoration: "underline",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "12px" }} />
            {t("common.register.button3")}
          </Typography>
        </CardContent>
      </Card>
      <Box mt={2} sx={{ width: isSmall ? "100%" : "90%", margin: "20px auto" }}>
        <Typography variant="h6" sx={{ color: "#3b82f6" }}>
          {t("common.register.p_title1")}
        </Typography>
        <Typography variant="body2">{t("common.register.p_disc1")}</Typography>
      </Box>
    </Container>
  );
};

export default ResetPassword;
