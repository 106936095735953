import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import selectLogo from "../../../assets/images/select_logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import axios from "../../../config/axios";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { API_ROUTES, APP_ROUTES, LEDGER } from "../../../utils/constants";
import { getApiDomain } from "../../../config/frontendConfig";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import InnerLoader from "../Loader/InnerLoader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CachedIcon from "@mui/icons-material/Cached";

const WalletCommon = () => {
  const navigate = useNavigate();
  async function logoutClicked() {
    await signOut();
    localStorage.clear();
    navigate("/");
    toast.success("Logout successfully.");
  }
  const client = useAxiosPrivate(axios);
  const session: any = useSessionContext();
  const { t, i18n } = useTranslation();

  const [walletData, setWalletData] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<any>("");

  const GetProfile = async () => {
    try {
      let response = await client.get(API_ROUTES.Get_Profile);
      console.log("response ::", response);
      // setBankInfo(response.data.data.userInfo.bankAccountAdded);
      const mobile = await response.data.data.userInfo.mobile;
      const name = await response.data.data.userInfo.email;
      mobile
        ? localStorage.setItem("user", mobile)
        : localStorage.setItem("user", name);
      setUser(mobile ? mobile : name);
    } catch (error) {
    } finally {
    }
  };

  const GetWalletSummery = async () => {
    try {
      setLoading(true);
      let response = await client.post(API_ROUTES.WALLET_SUMMERY, {
        accountId: session.userId,
        ledgerName: LEDGER.Dev_Ledger,
      });
      const usd = response?.data?.data.balances.USD
        ? response?.data?.data.balances.USD
        : "0.00";
      setWalletData(usd);
      localStorage.setItem("balance", usd);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const balance = localStorage.getItem("balance");
  const userProfile = localStorage.getItem("user");
  useEffect(() => {
    balance ? setWalletData(balance) : GetWalletSummery();
    // GetProfile();
    userProfile ? setUser(userProfile) : GetProfile();
  }, [balance, userProfile]);
  return (
    <Box bgcolor={"white"} p={2} boxShadow={1} borderRadius={4}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ width: "95%", margin: "10px auto", position: "relative" }}
      >
        <Avatar src={selectLogo} />
        <Box ml={2}>
          <Typography variant="body1">
            {user ? (
              user
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
            )}
          </Typography>

          <Typography
            variant="h5"
            fontWeight={600}
            sx={{ display: "flex", alignItems: "center", gap: 0.2 }}
          >
            {walletData ? (
              walletData
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1,5rem" }} width={70} />
            )}
            <IconButton onClick={GetWalletSummery} disabled={loading}>
              <CachedIcon
                sx={{
                  color: "blue",
                  transform: ` rotate(${loading ? -180 : 0}deg)`,
                  transition: " transform 1s linear",
                }}
                fontSize="small"
              />
            </IconButton>
          </Typography>
        </Box>
        <Typography
          sx={{ position: "absolute", right: "0", top: "0", cursor: "pointer" }}
          onClick={logoutClicked}
        >
          <LogoutIcon />
        </Typography>
      </Box>
      <Box
        display="flex"
        sx={{
          justifyContent: "space-between",
          width: "95%",
          margin: "10px auto",
        }}
      >
        <Button
          variant="contained"
          sx={{
            width: "49%",
            background: "#059b65",
            fontWeight: "600",
            textTransform: "none",
            "&:hover": { background: "#059b65" },
          }}
          onClick={() => navigate(APP_ROUTES.Topup)}
        >
          {t("sidebar.topup")}
        </Button>
        <Button
          variant="contained"
          sx={{
            width: "49%",
            background: "#fd8a46",
            fontWeight: "600",
            textAlign: "center",
            textTransform: "none",
            "&:hover": { background: "#fd8a46" },
          }}
          onClick={() => navigate(APP_ROUTES.Withdraw)}
        >
          {t("sidebar.withdraw")}
        </Button>
      </Box>
    </Box>
  );
};

export default WalletCommon;
