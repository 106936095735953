import {
  createTheme,
  useMediaQuery,
  Box,
  Container,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import promotion from "../../assets/images/promotion.png";
import { useTranslation } from "react-i18next";

const Promotion = () => {
  const [provider, setProvider] = React.useState("none");

  const handleChange = (event: any) => {
    setProvider(event.target.value);
    console.log(provider, "pv");
  };
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [selectedTab, setSelectedTab] = useState(0);
  const { t, i18n } = useTranslation();
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <Box component="img" src={promotion} />
        </Box>
        <Box sx={{ display: "flex", gap: 1, width: "80%", margin: "0 auto" }}>
          <Button
            variant={selectedTab == 0 ? "contained" : "outlined"}
            size="small"
            fullWidth
            sx={{
              borderColor: "white",
              p: 1,
              color: selectedTab == 0 ? "#c0003a" : "white", // Text color for the second button
              backgroundColor: selectedTab == 0 ? "white" : "",
              textTransform: "none",

              "&:hover": {
                borderColor: "white", // Background color for the second button
                backgroundColor: selectedTab == 0 ? "white" : "",
              },
              fontWeight: "600",
            }}
            onClick={() => setSelectedTab(0)}
          >
            {t("promotion.button1")}
          </Button>
          <Button
            size="small"
            variant={selectedTab == 1 ? "contained" : "outlined"}
            fullWidth
            sx={{
              borderColor: "white",
              color: selectedTab == 1 ? "#c0003a" : "white", // Text color for the second button
              backgroundColor: selectedTab == 1 ? "white" : "",
              textTransform: "none",
              p: 1,
              "&:hover": {
                borderColor: "white", // Background color for the second button
                backgroundColor: selectedTab == 1 ? "white" : "",
              },
              fontWeight: "600",
            }}
            onClick={() => setSelectedTab(1)}
          >
            {t("promotion.button2")}
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Promotion;
