import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  Container,
  createTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import { doesEmailExist } from "supertokens-web-js/recipe/thirdpartyemailpassword";

import { sendPasswordResetEmail } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import LoginPopup from "../../components/common/LoginPopup";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const ForgetPassword = () => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [registerData, setRegisterData] = useState({
    password: "",
    username: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  async function checkEmail(email: string) {
    try {
      setLoading(true);
      let response = await doesEmailExist({
        email,
      });

      if (response.doesExist) {
        return true;
      } else {
        toast.error("Email is not exists. Please do register");
        return false;
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
      return false;
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (name: string, value: string) => {
    setErrorMsg("");
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const validateEmailPhoneInput = (field: string) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/im;
    let check: any = "invalid";
    if (emailRegex.test(field)) {
      //it's an email address
      check = "email";
    } else if (phoneRegex.test(field)) {
      //it's a phone number
      check = "phone";
    } else {
      //display your message or highlight your field or whatever.
      // field.classList.add("invalid");
      check = "invalid";
      toast.error("Enter valid phone number or email address");
    }
    return check;
  };

  async function sendEmailClicked() {
    try {
      console.log("passwordLess ::", registerData);

      const check = validateEmailPhoneInput(registerData.username);

      if (check === "email" || check === "phone") {
        const checkMailExist = await checkEmail(registerData.username);

        setLoading(true);
        if (checkMailExist) {
          let response: any = await sendPasswordResetEmail({
            formFields: [
              {
                id: "email",
                value: registerData.username,
              },
            ],
          });

          if (response.status === "FIELD_ERROR") {
            // one of the input formFields failed validaiton
            response.formFields.forEach((formField: any) => {
              if (formField.id === "email") {
                // Email validation failed (for example incorrect email syntax).
                toast.error(formField.error || "Enter valid email address");
              }
            });
          } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
            // this can happen due to automatic account linking. Please read our account linking docs
          } else {
            // reset password email sent.
            toast.success(
              "Please check your email for the password reset link"
            );
          }
        }
      }
    } catch (err: any) {
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        toast.error(err.message);
      } else {
        toast.error("Oops! Something went wrong.");
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container maxWidth={isSmall ? "sm" : "md"}>
      <Card
        sx={{
          borderRadius: "0.50rem",
          width: isSmall ? "100%" : "90%",
          margin: "0 auto",
          p: 0,
          boxShadow: (theme) =>
            `0 10px 15px -3px ${theme.palette.primary.main}, 0 4px 6px -4px rgba(0,0,0,.1)`,
        }}
      >
        <LoginPopup open={open} handleClose={handleClose} />
        <CardContent sx={{ width: "90%", margin: "0 auto", p: 0 }}>
          <h1 style={{ textAlign: "center" }}>{t("title")}</h1>
          <TextField
            autoFocus
            margin="dense"
            type="email"
            fullWidth
            size="small"
            value={registerData.username}
            placeholder={t("common.loginpop.email")}
            onChange={(e: any) => handleChange("username", e.target.value)}
            inputProps={{
              sx: {
                background: "white",
                borderRadius: "0.40rem",
                fontWeight: "600",
                "&::placeholder": {
                  color: "#910015",
                  fontWeight: "600",
                },
              },
            }}
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              mt: 1,
              background: "#00976a",
              textTransform: "none",
              fontWeight: "500",
              "&:hover": { background: "#00976a" },
            }}
            onClick={sendEmailClicked}
          >
            {loading ? ( // Render the loader when loading is true
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              t("common.register.button2")
            )}
          </Button>
          <Typography
            variant="body2"
            sx={{
              textAlign: "center",
              my: 2,
              textDecoration: "underline",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon sx={{ fontSize: "12px" }} />
            {t("common.register.button3")}
          </Typography>
        </CardContent>
      </Card>
      <Box mt={2} sx={{ width: isSmall ? "100%" : "90%", margin: "20px auto" }}>
        <Typography variant="h6" sx={{ color: "#3b82f6" }}>
          {t("common.register.p_title1")}
        </Typography>
        <Typography variant="body2">{t("common.register.p_disc1")}</Typography>
      </Box>
    </Container>
  );
};

export default ForgetPassword;
