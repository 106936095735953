import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  InputAdornment,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import WalletCommon from "../../components/common/WalletCommon";
import casino from "../../assets/images/casino.gif";
import ImageData from "../../components/ImageData";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import { GameStrapi, getHeaders } from "../../config/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";

const Provider = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [search, setSearch] = useState("");
  const strapiClient = useAxiosPrivate(GameStrapi);
  let header = "";
  const [gameData, setGameData] = useState([]);
  const [tempArr, setTempArr] = useState([]);
  const [tempArr2, setTempArr2] = useState([]);
  const [category, setcategory] = useState([]);
  const skeletonArray = ["", "", "", "", "", "", ""];
  const skeletonArray2 = ["", "", ""];
  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = () => {
    const temp = tempArr2.filter((ele: any) => {
      if (ele.name.toLowerCase().includes(search.toLowerCase())) return 1;
    });
    setTempArr(search ? temp : [...tempArr2]);
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {
    handleChange();
  }, [search]);

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setSelectedTab(newValue);
  };

  const GetHeader = () => {
    try {
      const tokenStr: any = localStorage.getItem("token");
      header = JSON.parse(tokenStr);
    } catch (error) {}
  };

  const getGame = async (header: any) => {
    try {
      const res = await strapiClient.get(
        API_ROUTES.Get_Game.replace(":id", "" + id),
        header
      );
      console.log("getGame ::", res);
      const data = res.data.data;
      const temp: any = [];
      data.map((ele: any) => {
        const name = ele.attributes.name;
        const url = `https://uat-strapi.pugs.team${ele?.attributes?.url?.data?.[0]?.attributes?.url}`;
        const category = ele.attributes.category;
        const id = ele.id;
        temp.push({ name: name, url: url, category: category, id: id });
      });
      const temp2: any = [];
      temp.map((ele: any) => {
        if (!temp2.includes(ele?.category)) {
          temp2.push(ele?.category);
        }
      });
      setGameData(temp);
      setTempArr(temp);
      setTempArr2(temp);
      setcategory(temp2);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetHeader();
  }, []);

  useEffect(() => {
    getGame(header);
  }, [header]);

  const handleCategory = (category: any, index: any) => {
    setSelectedTab(index);
    const temp = gameData.filter((ele: any) => {
      if (ele.category === category) return 1;
    });
    setTempArr(temp);
    setTempArr2(temp);
  };

  const handleAllGames = () => {
    setSelectedTab(0);
    setTempArr(gameData);
    setTempArr2(gameData);
  };

  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <WalletCommon />
        <h2 style={{ textAlign: "center", color: "white" }}>
          {t("provider.title")}
        </h2>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={search}
            fullWidth
            size="small"
            sx={{
              backgroundColor: "white",
              borderRadius: "0.75rem",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              sx: {
                width: "100%",
                borderRadius: "0.75rem",
              },
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: 2,
            my: 2,
          }}
        >
          {category.length ? (
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "white", // Background color for the second button
                color: "#c0003a", // Text color for the second button
                "&:hover": {
                  backgroundColor: "white", // Background color for the second button
                },
                fontWeight: "600",
                border: selectedTab == 0 ? "2px solid #c0003a" : "",
              }}
              onClick={handleAllGames}
            >
              {t("provider.button1")}
            </Button>
          ) : (
            <Skeleton variant="rounded" width={100} height={40} />
          )}

          {category.length
            ? category.map((ele: any, index) => (
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: "white", // Background color for the second button
                    color: "#c0003a", // Text color for the second button
                    "&:hover": {
                      backgroundColor: "white", // Background color for the second button
                    },
                    fontWeight: "600",
                    border: selectedTab == index + 1 ? "2px solid #c0003a" : "",
                  }}
                  onClick={() => handleCategory(ele, index + 1)}
                >
                  {ele}
                </Button>
              ))
            : skeletonArray2.map((ele: any) => (
                <Skeleton variant="rounded" width={100} height={40} />
              ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            flexWrap: "wrap",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {tempArr.length
            ? tempArr.map((ele: any) => (
                <Box mt={1}>
                  <CardMedia
                    component="img"
                    image={ele.url}
                    sx={{ height: "180px", width: "130px", cursor: "pointer" }}
                    onClick={() =>
                      navigate(APP_ROUTES.PlayGame.replace(":id", ele.id))
                    }
                  />
                  <Typography
                    textAlign="center"
                    sx={{ color: "#0b55ad", fontWeight: "600", mt: 1 }}
                  >
                    {ele.name}
                  </Typography>
                </Box>
              ))
            : skeletonArray.map((ele: any) => (
                <Skeleton variant="rounded" width={150} height={200} />
              ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Provider;
