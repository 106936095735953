import {
  createTheme,
  useMediaQuery,
  Box,
  Container,
  CardMedia,
  Typography,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import WalletCommon from "../../components/common/WalletCommon";
import sport from "../../assets/images/sport.gif";
import ImageData from "../../components/ImageData";
import { useNavigate } from "react-router-dom";
import { getHeaders, GameStrapi } from "../../config/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import ProvidePH from "../../assets/images/providerPH.jpeg";

const Sports = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [gameData, setGameData] = useState<any>([]);
  const navigate = useNavigate();
  let header = "";
  const skeletonArray = ["", "", "", "", "", "", ""];

  const GetHeader = () => {
    try {
      const tokenStr: any = localStorage.getItem("token");
      header = JSON.parse(tokenStr);
    } catch (error) {}
  };

  const strapiClient = useAxiosPrivate(GameStrapi);
  const getCategory = async (header: any) => {
    try {
      const response = await strapiClient.get(
        API_ROUTES.Game_Sports_list,
        header
      );
      const res = response.data.data;
      console.log("response ::", res);
      const temp: any = [];

      res?.map((ele: any) => {
        const name = `${ele.attributes.firstName} ${
          ele.attributes?.lastName ?? ""
        }`;
        const url = ele.attributes?.profilePicture?.data?.[0]?.attributes?.url
          ? `https://uat-strapi.pugs.team${ele.attributes?.profilePicture?.data?.[0]?.attributes?.url}`
          : ProvidePH;
        const id = ele.id;
        temp.push({ name: name, url: url, id: id });
      });
      setGameData(temp);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetHeader();
  }, []);

  useEffect(() => {
    getCategory(header);
  }, [header]);

  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <WalletCommon />
        <Box
          sx={{ width: { xs: "250px", sm: "360px" }, margin: "0 auto", my: 2 }}
        >
          <CardMedia component="img" image={sport} />
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {gameData.length
            ? gameData.map((ele: any) => (
                <Box
                  onClick={() => {
                    navigate(APP_ROUTES.Provider.replace(":id", ele.id));
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <CardMedia
                    component="img"
                    image={ele.url}
                    sx={{ height: "180px", width: "130px" }}
                  />
                  <Typography
                    textAlign="center"
                    sx={{ color: "#0b55ad", fontWeight: "600", mt: 1 }}
                  >
                    {ele.name}
                  </Typography>
                </Box>
              ))
            : skeletonArray.map((ele: any) => (
                <Skeleton variant="rounded" width={150} height={200} />
              ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Sports;
