export const APP_ROUTES = {
  Landing: "/",
  Login: "/login",
  NotFound: "/login/",
  Home: "/home",
  Profile: "/profile",
  Wallet: "/wallet",
  Register: "/register",
  Forget: "/forget",
  ResetPassword: "auth/reset-password",
  Dashboard: "/dashboard",
  Casino: "/casino",
  Slots: "/slots",
  Sports: "/sports",
  Provider: "/game/:id",
  Topup: "/topup",
  Withdraw: "/withdraw",
  History: "/history",
  Promotion: "/promotion",
  BankPage: "/bank",
  PlayGame: "/games/game-id/:id",
  PopularGames: "/popular-games/:name",
};

export const API_ROUTES = {
  WALLET_SUMMERY: "/p/v1/ledger/account-summary",
  WALLET_TRANSACTION: "/p/v1/ledger/transactions",

  Get_Profile: "/p/v1/profile",
  Update_Profile: "/p/v1/profile/update",

  //bank page
  ADD_BANK: "/p/v1/profile/update-bank-account",
  GET_BANK: "/v1/banks/get-all",
  SUPPORT_BANK: "/p/v1/banks/get-supported-bank/:userId",

  //strapi api
  Get_header: "/p/v1/strapi/get-viewer-token",
  Game_Populate: "games?populate=*",
  Game_Popular: "/tags?populate=games.url&filters[name][$eq]=PopularGames",
  Game_Slots: "/tags?populate=games.url&filters[name][$eq]=PopularSlots",
  Game_Casino: "/tags?populate=games.url&filters[name][$eq]=PopularCasino",
  Game_Sports: "/tags?populate=games.url&filters[name][$eq]=PopularSports",

  //get games
  Get_Game: "/games?populate=*&filters[game_provider][id]=:id",

  //get popular games
  Get_Popular: "/tags?populate=games.url&filters[name][$eq]=type",

  //get provider
  Get_provider: "/game-providers",
  Get_provider_category: "game-providers/:id?populate[0]=game_categories",

  //play games:
  Play_Game: "/games?populate=*&filters[id]=:id",
  Start_Game: "/p/v1/gameplay/start",
  Get_Start_Game_API: "/p/v1/gameplay/start-game",
  Game_State: "/p/v1/gameplay/get-all?userId=:uid&gameId=:id",

  //add depostie
  Add_Depostie: "/p/v1/transferHistory/deposit/request",

  //add Widthdraw
  Get_Widthdraw: "/p/v1/transferHistory/withdrawal/request",

  //get history
  Get_History: "/p/v1/transferHistory/get-all",

  //category
  Game_Casino_list:
    "/game-providers?populate=profilePicture&filters[$and][0][game_categories][name][$eq]=Live Casino",
  Game_Slots_list:
    "/game-providers?populate=profilePicture&filters[$and][0][game_categories][name][$eq]=Slot",
  Game_Sports_list:
    "/game-providers?populate=profilePicture&filters[$and][0][game_categories][name][$eq]=Sport Book",
};

export const LEDGER = {
  Dev_Ledger: "testSDKLedger2",
};
