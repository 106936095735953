import React, { useEffect } from "react";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import "./styles.css";
import CommonForm from "../../components/common/Form";
import { thirdPartySignInAndUp } from "supertokens-web-js/recipe/thirdpartyemailpassword";
import { useNavigate, useParams } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import Loader from "../../components/common/Loader/Loader";
import { Box } from "@mui/material";

const Login = () => {
  const [isload, setIsLoad] = React.useState(false);
  const { params } = useParams();
  const navigate = useNavigate();
  console.log("params ::", params);
  const session: any = useSessionContext();

  console.log("session ::", session);
  useEffect(() => {
    if (!session.userId) {
      handleGoogleCallback();
    }

    if (session?.userId) {
      navigate(APP_ROUTES.Home);
    }
  }, []);

  async function handleGoogleCallback() {
    try {
      setIsLoad(true);
      const response = await thirdPartySignInAndUp();

      console.log("Call ::", response);
      if (response.status === "OK") {
        if (response.createdNewUser) {
          // sign up successful
          navigate(APP_ROUTES.BankPage);
        } else {
          // sign in successful
        }
        // window.location.assign("/home");
        navigate(APP_ROUTES.Dashboard);
      } else {
        // SuperTokens requires that the third party provider
        // gives an email for the user. If that's not the case, sign up / in
        // will fail.

        // As a hack to solve this, you can override the backend functions to create a fake email for the user.

        window.alert(
          "No email provided by social login. Please use another form of login"
        );
        window.location.assign("/auth"); // redirect back to login page
      }
    } catch (err: any) {
      console.log("ERR ::", err);
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else if (err.type != "cors") {
        window.alert("Oops! Something went wrong.");
      }
    } finally {
      setIsLoad(false);
    }
  }

  return <Box>{isload && <Loader />}</Box>;
};

export default Login;
