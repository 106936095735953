import { Box, Button, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios, { GameStrapi } from "../../config/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import GameTable from "./Table";
import { set } from "react-hook-form";

const GamePlay = () => {
  const strapiClient = useAxiosPrivate(GameStrapi);
  const { id } = useParams();
  const [gameData, setGameData] = useState<any>({});
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const session: any = useSessionContext();
  const client = useAxiosPrivate(axios);
  const [play, setPlay] = useState(0);
  const [stateData, setStateData] = useState<any>([]);

  const getGame = async (header: any) => {
    try {
      setLoading(true);
      const response = await strapiClient.get(
        API_ROUTES.Play_Game.replace(":id", "" + id),
        header
      );
      console.log("response ::", response.data);
      const data = response.data.data[0].attributes;
      const url = `https://uat-strapi.pugs.team${data?.url?.data?.[0]?.attributes?.url}`;
      const name = data.name;
      const providerData = data.game_provider.data;
      const ProviderDetails = await strapiClient.get(
        API_ROUTES.Get_provider_category.replace(":id", "" + providerData.id),
        header
      );
      console.log("providerData ::", providerData);
      const providerCategory =
        ProviderDetails?.data?.data?.attributes?.game_categories?.data?.[0]
          ?.attributes;
      const price = data.price;
      const temp = {
        name: name,
        price: price,
        providerName: `${providerData.attributes.firstName} ${
          providerData.attributes?.lastName ?? ""
        }`,
        url: url,
        providerId: providerData.id,
        gameProviderId: providerData.attributes.providerCode,
        gameType: providerCategory.gameType,
        gameId: data.game_code,
      };
      setGameData(temp);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const getState = async () => {
    try {
      setIsLoading(true);
      const respone = await client.get(
        API_ROUTES.Game_State.replace(":uid", session?.userId).replace(
          ":id",
          "" + id
        )
      );
      setStateData(respone.data.data);
      console.log("response state ::", respone.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const playGame = async () => {
    try {
      setIsLoading(true);
      const data = {
        gameProviderId: gameData.gameProviderId,
        gameType: gameData.gameType,
        gameId: gameData.gameId,
      };
      console.log("playGame data::", data);
      const respone = await client.post(API_ROUTES.Get_Start_Game_API, data);
      // setPlay(play + 1);
      console.log("playGame ::", respone.data);

      window.location.assign(respone.data.launchUrl);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const tokenStr: any = localStorage.getItem("token") || null;
  let header: any = tokenStr ? JSON.parse(tokenStr) : null;
  const [strapiHeader, setStrapiHeader] = useState(header);

  useEffect(() => {
    getGame(strapiHeader);
    getState();
  }, []);

  useEffect(() => {
    getState();
  }, [play]);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "65vh",
        }}
      >
        {loading ? (
          <Skeleton
            variant="rounded"
            width={200}
            height={290}
            animation="wave"
          />
        ) : (
          <Box component="img" width={200} src={gameData.url} />
        )}
        <Box mt={2}>
          {loading ? (
            <Skeleton variant="text" width={200} sx={{ fontSize: "2rem" }} />
          ) : (
            <Typography variant="h6" fontWeight={600} color="#D3114C">
              {t("gamePlay.title1")} {gameData.name}
            </Typography>
          )}
          {loading ? (
            <Skeleton variant="text" width={200} sx={{ fontSize: "2rem" }} />
          ) : (
            <Typography variant="h6" fontWeight={600} color="#D3114C">
              {t("gamePlay.providerName")} {gameData.providerName}
            </Typography>
          )}
        </Box>
        {!loading && (
          <Button
            variant="contained"
            sx={{
              width: "200px",
              background: "#059b65",
              fontWeight: "500",
              mt: 2,
              "&:hover": { background: "#059b65" },
            }}
            onClick={playGame}
          >
            Play
          </Button>
        )}
      </Box>
      <Box>
        {!isLoading ? (
          stateData.length > 0 && <GameTable data={stateData} />
        ) : (
          <Skeleton
            variant="rounded"
            height={200}
            sx={{ width: { xs: "80%", md: "630px" }, margin: "0 auto" }}
          />
        )}
      </Box>
    </Box>
  );
};

export default GamePlay;
