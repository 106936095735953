import axios from "axios";

const env = process.env;

export default axios.create({
  baseURL: env.REACT_APP_API_URL || "https://uat-api.pugs.team",
});

export const GameStrapi = axios.create({
  baseURL:
    env.REACT_APP_GAME_STRAPI_API_URL || "https://uat-strapi.pugs.team/api",
});

export async function getHeaders() {
  try {
    const response = await axios.post(
      `${env.REACT_APP_GAME_STRAPI_API_URL}/auth/local`,
      {
        identifier: env.REACT_APP_STRAPI_USERNAME,
        password: env.REACT_APP_STRAPI_PASSWORD,
      }
    );

    const token = response.data.jwt;

    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {}
}
