import { styled } from "styled-components";
import { LandingBg } from "../../assets/images";

export const HeaderSection = styled.header`
  background: transparent;
  color: white;
  width: 100%;
`;

export const HeaderMain = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    text-decoration: none;
    color: white;
  }
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  width: 100%;
  cursor: pointer;

  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);

  &:hover {
    background-color: var(--mauve-3);
  }
  &:focus {
    box-shadow: 0 0 0 2px black;
  }
`;
