import {
  createTheme,
  useMediaQuery,
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Button,
  Grid,
  Input,
  TextField,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import kbank from "../../assets/images/KBANK.png";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../config/axios";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { API_ROUTES, APP_ROUTES, LEDGER } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const Withdraw = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [amount, setAmount] = useState("100");
  const { t, i18n } = useTranslation();
  const client = useAxiosPrivate(axios);
  const session: any = useSessionContext();
  const [bankAmount, setBankAmount] = useState<any>(0);
  const [playerBank, setPlayerBank] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const navigate = useNavigate();

  // const getBank = async () => {
  //   try {
  //     const res = await client.get(
  //       API_ROUTES.SUPPORT_BANK.replace(":userId", session?.userId)
  //     );
  //     setBankData(res.data?.id);
  //     // console.log(res.data, "support bank");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const GetProfile = async () => {
    try {
      setLoading(true);
      let response = await axios.get(API_ROUTES.Get_Profile);
      console.log("response ::", response);
      const mobile = await response.data.data.userInfo.mobile;
      const name = await response.data.data.userInfo.email;
      mobile
        ? localStorage.setItem("user", mobile)
        : localStorage.setItem("user", name);

      const bankId = response.data.data.userInfo.bankId;
      setPlayerBank({
        id: bankId,
        account: response.data.data.userInfo.accountDetails.accountNumber,
        name:
          response.data.data.userInfo.accountDetails.firstName +
          " " +
          response.data.data.userInfo.accountDetails.lastName,
      });
      setLoading(false);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const GetWalletSummery = async () => {
    try {
      setLoading1(true);
      let response = await client.post(API_ROUTES.WALLET_SUMMERY, {
        accountId: session.userId,
        ledgerName: LEDGER.Dev_Ledger,
      });
      const usd = response?.data?.data.balances.USD
        ? response?.data?.data.balances.USD
        : "0.00";
      setBankAmount(usd);
      localStorage.setItem("balance", usd);
      setLoading1(false);
    } catch (error) {
    } finally {
      setLoading1(false);
    }
  };

  const getWithdraw = async () => {
    try {
      setLoading1(true);
      const formData: any = new FormData();
      formData.append("playerId", session?.userId);
      formData.append("playerBankId", playerBank.id);
      formData.append("amount", amount);
      const res = await client.post(API_ROUTES.Get_Widthdraw, formData);
      console.log("respone ::", res.data);
      navigate(APP_ROUTES.Wallet);
      setLoading1(false);
      toast.success("Withdrawal request successfully.");
    } catch (error: any) {
      setLoading1(false);
      toast.error(error?.response?.data?.error);
      // toast.error("Oops! Something went wrong.");
    }
  };
  const balance = localStorage.getItem("balance");
  useEffect(() => {
    // getBank();
    GetProfile();
    GetWalletSummery();
  }, []);
  useEffect(() => {
    // getBank();
    setBankAmount(balance);
  }, [balance]);
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#EEEFFF",
            borderRadius: "0.75rem",
          }}
        >
          <CardContent
            sx={{
              width: "100%",
              pb: "8px !important",
              p: "8px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" fontWeight={600}>
              {t("withdraw.title1")}
            </Typography>
            <Typography variant="h6" fontWeight={600}>
              {bankAmount || "0.00"}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#8B0724",
            borderRadius: "0.75rem",
            my: 1.5,
          }}
        >
          {!loading ? (
            <CardMedia
              component="img"
              src={kbank} // Replace with your image URL
              alt="Image"
              sx={{ width: 60, height: 60, ml: 3 }} // Adjust image dimensions and spacing
            />
          ) : (
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              sx={{ ml: 3 }}
            />
          )}
          <CardContent sx={{ pb: "8px !important", p: "8px" }}>
            <Typography variant="body2" style={{ color: "#FFDA16" }}>
              {!loading ? (
                t("topup.title2")
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={150}
                />
              )}
            </Typography>
            <Typography color="white" variant="h6">
              {!loading ? (
                playerBank.account
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={150}
                />
              )}
            </Typography>
            <Typography color="white" variant="body1">
              {!loading ? (
                playerBank.name
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={150}
                />
              )}
            </Typography>
          </CardContent>
        </Card>
        <Typography variant="h6" color="white">
          {t("withdraw.title3")}
        </Typography>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFB3C5",
            borderRadius: "0.75rem",
            my: 1.5,
          }}
        >
          <CardContent
            sx={{
              pb: "12px !important",
              p: "12px",
              margin: "0 auto",
            }}
          >
            <Typography variant="body2" style={{ color: "#A50031" }}>
              {t("withdraw.title4")}
            </Typography>
            <Typography variant="body2" style={{ color: "#A50031" }}>
              {t("withdraw.title5")}
            </Typography>
          </CardContent>
        </Card>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={9}>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              value={amount}
              fullWidth
              sx={{
                backgroundColor: "white",
                textAlign: "center",
              }}
              inputProps={{
                sx: {
                  color: "#0677e5",
                  textAlign: "center",
                  fontWeight: "600",
                },
              }}
              onChange={(e: any) => {
                setAmount(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "#37458E",
                textTransform: "none",
                "&:hover": { backgroundColor: "#37458E" },
              }}
              onClick={() => {
                setAmount(bankAmount);
              }}
            >
              {t("withdraw.button1")}
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: { xs: "100%", sm: "80%" },
            margin: "0 auto",
            mt: 2,
          }}
        >
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  color: "#D3114C",
                  borderColor: "#D3114C",
                  whiteSpace: "nowrap",
                  fontWeight: "600",
                  "&:hover": { borderColor: "#D3114C" },
                }}
                onClick={() => {
                  setAmount("100");
                }}
              >
                100 baht
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  color: "#D3114C",
                  borderColor: "#D3114C",
                  whiteSpace: "nowrap",
                  fontWeight: "600",
                  "&:hover": { borderColor: "#D3114C" },
                }}
                onClick={() => {
                  setAmount("500");
                }}
              >
                500 baht
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  color: "#D3114C",
                  borderColor: "#D3114C",
                  whiteSpace: "nowrap",
                  fontWeight: "600",
                  "&:hover": { borderColor: "#D3114C" },
                }}
                onClick={() => {
                  setAmount("1000");
                }}
              >
                1,000 baht
              </Button>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Button
                variant="outlined"
                fullWidth
                sx={{
                  color: "#D3114C",
                  borderColor: "#D3114C",
                  whiteSpace: "nowrap",
                  fontWeight: "600",
                  "&:hover": { borderColor: "#D3114C" },
                }}
                onClick={() => {
                  setAmount("2000");
                }}
              >
                2,000 baht
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <Typography variant="body2" textAlign="center">
            {t("withdraw.disc1")}
          </Typography>
          <Typography variant="body2" textAlign="center">
            {t("withdraw.disc2")}
          </Typography>
        </Box>
        <Button
          size="large"
          variant="contained"
          fullWidth
          sx={{
            textTransform: "none",
            backgroundColor: "#00976a",
            mt: 2,
            "&:hover": { backgroundColor: "#00976a" },
          }}
          disabled={
            !amount ||
            !(Number(amount) > 0) ||
            loading ||
            loading1 ||
            Number(bankAmount) < Number(amount)
          }
          onClick={getWithdraw}
          // onClick={() => console.log("amount ::", Number(amount) > 0)}
        >
          {loading || loading1 ? ( // Render the loader when loading is true
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            t("withdraw.button2")
          )}
        </Button>
        <Button
          variant="contained"
          size="large"
          fullWidth
          sx={{
            mt: 1.5,
            textTransform: "none",
            backgroundColor: "#272A40",
            "&:hover": { backgroundColor: "#272A40" },
          }}
          onClick={() => navigate(APP_ROUTES.Dashboard)}
        >
          {t("withdraw.button3")}
        </Button>
      </Container>
    </Box>
  );
};

export default Withdraw;
