import React from "react";
import * as S from "./Header.style";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { Link, useNavigate } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

import {
  Box,
  Container,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import RightDrawer from "../common/RightDrawer";
import { APP_ROUTES } from "../../utils/constants";
import { useTranslation } from "react-i18next";

const Header = () => {
  const navigate = useNavigate();
  const session = useSessionContext();

  const { t, i18n } = useTranslation();

  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth={isSmall ? "sm" : "md"}>
      <Box>
        <S.HeaderSection>
          <S.HeaderMain className="container">
            {session?.userId ? (
              <Link to={APP_ROUTES.Dashboard}>
                <h1 style={{ cursor: "Pointer" }}>{t("title")}</h1>
              </Link>
            ) : (
              <Link to="/">
                <h1 style={{ cursor: "Pointer" }}>{t("title")}</h1>
              </Link>
            )}
            <RightDrawer />
            {/* <div>
          {session?.userId && (
            <S.StyledButton onClick={logoutClicked}>Sign Out</S.StyledButton>
          )}
        </div> */}
          </S.HeaderMain>
        </S.HeaderSection>
      </Box>
    </Container>
  );
};

export default Header;
