import {
  createTheme,
  useMediaQuery,
  Box,
  Container,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ImageData from "../../components/ImageData";
import { useTranslation } from "react-i18next";
import TranscationGame from "./TranscationGame";
import axios, { GameStrapi } from "../../config/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES } from "../../utils/constants";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import InfiniteScroll from "react-infinite-scroll-component";
import { Block } from "@mui/icons-material";
const History = () => {
  const [provider, setProvider] = useState("none");
  const { t, i18n } = useTranslation();
  const client = useAxiosPrivate(axios);
  const session: any = useSessionContext();
  const [gameData, setGameData] = useState<any>([]);
  const skeletonArray = ["", "", "", "", "", ""];

  const [page, setPage] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const strapiClient = useAxiosPrivate(GameStrapi);
  const [providerData, setProviderData] = useState([]);
  const [filterData, setfilterData] = useState<any>([]);

  const handleChange = (event: any) => {
    setProvider(event.target.value);
    if (event.target.value == "none") {
      setfilterData(gameData);
      return;
    }
    const temp = gameData.filter(
      (ele: any) => ele.provider === event.target.value
    );
    setfilterData(temp);
  };
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const tokenStr: any = localStorage.getItem("token") || null;
  let header: any = tokenStr ? JSON.parse(tokenStr) : null;
  const [strapiHeader, setStrapiHeader] = useState(header);

  const getGame = async (header: any) => {
    try {
      setLoading(true);
      const response = await strapiClient.get(API_ROUTES.Get_provider, header);
      console.log("response ::", response.data);
      const temp: any = [];
      response.data.data.map((ele: any) => {
        const id = ele.id;
        const name = `${ele.attributes.firstName} ${
          ele.attributes?.lastName ?? ""
        }`;
        temp.push({ name: name, id: id });
      });
      setProviderData(temp);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  const GetWalletTransaction = async (nextPage: any) => {
    try {
      setIsLoading(true);
      let response = await client.post(API_ROUTES.WALLET_TRANSACTION, {
        account: session?.userId,
        pageSize: 100,
        nextCursor: nextPage,
      });
      const data = response.data.data;
      console.log("data ::", data);
      setPage(response?.data?.nextCursor);
      const temp: any = [];

      data?.map((ele: any) => {
        const amount = ele.postings[0].amount;
        const time = ele.timestamp;
        const provider = ele.metadata?.gameDetails?.gameProviderName;
        const game = ele?.metadata?.gameDetails?.name;
        const depostied = ele.metadata?.type;
        temp.push({
          amount: amount,
          time: time,
          provider: provider,
          game: game,
          isDepostie: depostied,
        });
      });
      console.log("response?.data?.nextCursor ::", response?.data?.nextCursor);
      if (response?.data?.nextCursor) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      const newData = [...gameData, ...temp];
      setGameData(newData);
      const tempGame =
        provider != "none"
          ? newData.filter((ele: any) => ele.provider === provider)
          : newData;
      setfilterData(tempGame);

      console.log("GetWalletTransaction ::", response.data);
      setIsLoading(false);
    } catch (error) {
      console.log("ERR ::", error);
      setIsLoading(false);
    }
  };
  const handleNext = () => {
    GetWalletTransaction(page);
    console.log("call handleNext");
  };
  useEffect(() => {
    GetWalletTransaction(page);
    getGame(strapiHeader);
  }, []);
  console.log("gameData :: ", filterData);
  const [selectedTab, setSelectedTab] = useState(0);
  console.log("filterData.length :: ", filterData.length);
  console.log("hasMore: ", hasMore);

  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <Typography
          variant="h6"
          fontWeight={600}
          textAlign="center"
          color="white"
        >
          {t("history.title1")}
        </Typography>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
            width: "80%",
            margin: "8px auto",
          }}
        >
          <Button
            variant={selectedTab == 0 ? "contained" : "outlined"}
            fullWidth
            sx={{
              borderColor: "white",
              p: 1,

              color: selectedTab == 0 ? "#c0003a" : "white", // Text color for the second button
              backgroundColor: selectedTab == 0 ? "white" : "",
              textTransform: "none",

              "&:hover": {
                borderColor: "white", // Background color for the second button
                backgroundColor: selectedTab == 0 ? "white" : "",
              },
              fontWeight: "600",
            }}
            onClick={() => setSelectedTab(0)}
          >
            {t("history.button1")}
          </Button>
          <Button
            variant={selectedTab == 1 ? "contained" : "outlined"}
            fullWidth
            sx={{
              borderColor: "white",
              p: 1,

              color: selectedTab == 1 ? "#c0003a" : "white", // Text color for the second button
              backgroundColor: selectedTab == 1 ? "white" : "",
              textTransform: "none",
              "&:hover": {
                borderColor: "white", // Background color for the second button
                backgroundColor: selectedTab == 1 ? "white" : "",
              },
              fontWeight: "600",
            }}
            onClick={() => setSelectedTab(1)}
          >
            {t("history.button2")}
          </Button>
        </Box> */}
        <FormControl fullWidth>
          <Select
            value={provider}
            onChange={handleChange}
            size="small"
            fullWidth
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              backgroundColor: "white",
              mt: 1,
              textAlign: "center",
              color: "black",
            }}
          >
            <MenuItem key="" value="none">
              {t("playback.option1")}
            </MenuItem>
            {providerData.map((ele: any) => (
              <MenuItem key={ele.id} value={ele.name}>
                {ele.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          sx={{
            backgroundColor: "#ddd9da",
            borderRadius: "0.75rem",
            mt: 3,
            pb: 4,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              pt: 3,
              fontWeight: 600,
              color: "black",
            }}
          >
            {t("playback.title1")}
          </Typography>
          <Box
            id="infinite-scroll"
            sx={{
              width: "90%",
              margin: "0 auto",
              mt: 2,
              height: "550px",
              overflowY: "scroll",
            }}
          >
            {/* <InfiniteScroll
              dataLength={filterData.length}
              next={handleNext}
              hasMore={hasMore}
              loader={
                isLoading &&
                skeletonArray.map((ele: any) => (
                  <Box my={0.5}>
                    <Skeleton variant="rounded" width="100%" height={80} />
                  </Box>
                ))
              }
              scrollableTarget="infinite-scroll"
            > */}
            {filterData.map((ele: any) => (
              <TranscationGame
                addedAmount={ele.amount}
                date={ele.time}
                game={ele.game}
                provider={ele.provider}
                isDeposite={ele?.isDepostie}
              />
            ))}
            {hasMore && filterData.length != 0 ? (
              <Button variant="outlined" onClick={handleNext}>
                Load More
              </Button>
            ) : (
              <></>
            )}
            {/* </InfiniteScroll> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default History;
