import { createTheme, responsiveFontSizes } from "@mui/material";
import { red } from "@mui/material/colors";

// Create a theme instance.
let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 567,
      md: 768,
      lg: 992,
      xl: 1440,
    },
  },

  //     common: {
  //       black: "#000000",
  //       white: "#ffffff",
  //       grey: "#D9D9D9",
  //       darkBlue: "#003260",
  //       lightBlue: "#D9EDFF",
  //       pageBackground: "#FBFBFF",
  //       green: "#479706",
  //       orange: "#FBBF24",
  //       borderColor: "#2f2f2f",
  //     },
  //     gradient: {
  //       buttonBg: "linear-gradient(90.45deg, #4A3AFF 0.48%, #4A3AFF 99.8%",
  //     },
  //     primary: {
  //       light: "#4A3AFF",
  //       main: "#4A3AFF",
  //       dark: "#0f40ec",
  //       contrastText: "#ffffff",
  //     },
  //     secondary: {
  //       light: "#067ae6",
  //       main: "#0053A0",
  //       dark: "#0053A0",
  //       contrastText: "#ffffff",
  //     },
  //     error: {
  //       main: red.A700,
  //     },
  //   },
  typography: {
    fontFamily: "Inter",
    // color: "#000000",
    lineHeight: 1.3,
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightSemibold: "600",
    fontWeightBold: "700",
  },
});

theme = createTheme({
  ...theme,
  typography: {
    h1: {
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "32px",
      lineHeight: "48px",
      letterSpacing: "normal",
      color: "#000000",
    },
    h2: {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "24px",
      color: "#FFFFFF",
      lineHeight: "36px",
      letterSpacing: "normal",
      color: "#000000",
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "22px",
      lineHeight: "32px",
      letterSpacing: "normal",
      color: "#000000",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "28px",
      },
    },
    h6: {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "26px",
      letterSpacing: "normal",
      color: "#000000",
      [theme.breakpoints.down("lg")]: {
        fontSize: "16px", // Font size for screens below the "md" breakpoint
      },
    },
    subtitle1: {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      letterSpacing: "normal",
      color: "#000000",
    },
    subtitle2: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "normal",
      color: "#000000",
    },
    body1: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#000000",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px", // Font size for screens below the "md" breakpoint
      },
    },
    body2: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "14px",
      color: "#000000",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px", // Font size for screens below the "md" breakpoint
      },
    },
    button: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "16px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "14px", // Font size for screens below the "md" breakpoint
      },
    },
    caption: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "12px",
      color: "#000000",
    },
    overline: {
      fontFamily: "Inter",
      fontWeight: "400",
      color: "#FFFFFF",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: "16px",
          [theme.breakpoints.down("lg")]: {
            fontSize: "14px", // Font size for screens below the "md" breakpoint
          },
        },
      },
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "black",
            },
          },
        },
      },
    },
  },
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           textTransform: "capitalize",
  //           borderRadius: "2px",
  //         },
  //       },
  //     },

  //     MuiOutlinedInput: {
  //       styleOverrides: {
  //         notchedOutline: {
  //           //   borderColor: theme.palette.common.borderColor,
  //           //   color: "#FFFFFF",
  //           ":hover": {
  //             // borderColor: theme.palette.common.borderColor,
  //           },
  //           //   borderRadius: "20px",
  //         },
  //       },
  //     },
  //     MuiLink: {
  //       styleOverrides: {
  //         root: {
  //           textDecoration: "none",
  //         },
  //       },
  //     },
  //     MuiTextField: {
  //       styleOverrides: {
  //         root: {
  //           //   background: "#000000",
  //         },
  //       },
  //     },
  //     MuiPaper: {
  //       styleOverrides: {
  //         root: {
  //           //   bgcolor: "#000000",
  //         },
  //       },
  //     },
  //     MuiSvgIcon: {
  //       styleOverrides: {
  //         root: {
  //           color: "#FFFFFF",
  //         },
  //       },
  //     },
  //   },
});

export default theme;
