import React from "react";
import CallAPIView from "./CallAPIView";
import { Link } from "react-router-dom";
import { APP_ROUTES } from "../../utils/constants";

export default function SuccessView(props: { userId: string }) {
  let userId = props.userId;

  return (
    <div
      className="fill"
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        fontWeight: "bold",
        color: "#333333",
        paddingTop: "10px",
        paddingBottom: "40px",
      }}
    >
      <span
        style={{
          fontSize: "50px",
        }}
      >
        🥳🎉
      </span>
      Login successful
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      Your user ID is
      <div />
      {userId}
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      {/* <button>Profile</button> */}
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      ------------------------------------
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      <div style={{ height: "10px" }} />
      <div>
        <Link to={APP_ROUTES.Home}>Dashboard</Link>{" "}
        <Link to={APP_ROUTES.Landing}>Home</Link>{" "}
        <Link to={APP_ROUTES.Profile}>Profile</Link>{" "}
        <Link to={APP_ROUTES.Wallet}>Wallet</Link>
      </div>
    </div>
  );
}
