import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Typography,
  Box,
} from "@mui/material";

const Cards = (props: any) => {
  const { text, img } = props;
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "45px",
        borderRadius: "0.5rem",
      }}
    >
      <Box
        component="img"
        alt="User Avatar"
        src={img}
        sx={{
          width: "24px",
          height: "24px",
          objectFit: "contain",
          p: 2,
        }}
      />

      <Typography variant="body1" fontWeight={600}>
        {text}
      </Typography>
    </Card>
  );
};

export default Cards;
