import {
  Box,
  CardMedia,
  Container,
  Skeleton,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import WalletCommon from "../../components/common/WalletCommon";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { GameStrapi } from "../../config/axios";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PopularGames = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  let header = "";
  const strapiClient = useAxiosPrivate(GameStrapi);
  const { name } = useParams();
  const [popularGame, setPopularGame] = useState<any>([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const skeletonArray = ["", "", "", "", "", "", ""];

  const GetHeader = () => {
    try {
      const tokenStr: any = localStorage.getItem("token");
      header = JSON.parse(tokenStr);
    } catch (error) {}
  };
  const getGame = async (header: any) => {
    try {
      const res = await strapiClient.get(
        API_ROUTES.Get_Popular.replace("type", "" + name),
        header
      );
      console.log("res ::", res);
      const data = res.data.data[0].attributes.games.data;
      const tempList: any = [];
      data.map((ele: any) => {
        const url = ele?.attributes.url.data?.[0]?.attributes.url;
        const name = ele?.attributes.name;
        const id = ele?.id;
        tempList.push({
          url: `https://uat-strapi.pugs.team${url}`,
          id: id,
          name: name,
        });
      });
      setPopularGame(tempList);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetHeader();
  }, []);
  useEffect(() => {
    getGame(header);
  }, [header]);
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <WalletCommon />
        <h2 style={{ textAlign: "center", color: "white" }}>
          {name == "PopularGames" && <>{t("popular.title1")}</>}
          {name == "PopularSlots" && <>{t("popular.title2")}</>}
          {name == "PopularCasino" && <>{t("popular.title3")}</>}
          {name == "PopularSports" && <>{t("popular.title4")}</>}
        </h2>
        <Box
          sx={{
            display: "flex",
            gap: 1.5,
            flexWrap: "wrap",
            justifyContent: "center",
            alignSelf: "center",
          }}
        >
          {popularGame.length
            ? popularGame.map((ele: any) => (
                <Box mt={1}>
                  <CardMedia
                    component="img"
                    image={ele.url}
                    sx={{ height: "180px", width: "130px", cursor: "pointer" }}
                    onClick={() =>
                      navigate(APP_ROUTES.PlayGame.replace(":id", ele.id))
                    }
                  />
                  <Typography
                    textAlign="center"
                    sx={{ color: "#0b55ad", fontWeight: "600", mt: 1 }}
                  >
                    {ele.name}
                  </Typography>
                </Box>
              ))
            : skeletonArray.map((ele: any) => (
                <Skeleton variant="rounded" width={150} height={200} />
              ))}
        </Box>
      </Container>
    </Box>
  );
};

export default PopularGames;
