import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Contact = (props: any) => {
  const { t, i18n } = useTranslation();
  const { open, handleClose } = props;
  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          width: "80%",
          p: 0,
          margin: "0 auto",
          ".MuiDialog-paper": {
            border: "1px solid rgb(237 153 153/1)",
            borderRadius: "0.75rem",
            maxWidth: "350px",
            minWidth: "325px",
          },
        }}
      >
        <DialogTitle sx={{ p: 0 }} align="center">
          <h1>{t("title")}</h1>
        </DialogTitle>
        <DialogContent sx={{ width: "80%", margin: "0 auto", p: 0 }}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              background: "#72be2c",
              textTransform: "none",
              fontWeight: "500",
              mt: 1.2,
              "&:hover": { background: "#72be2c" },
            }}
          >
            {t("contactpop.button1")}
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              background: "#272A40",
              textTransform: "none",
              fontWeight: "500",
              mt: 1.2,
              "&:hover": { background: "#272A40" },
            }}
          >
            {t("contactpop.button2")}
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            sx={{
              background: "#272A40",
              textTransform: "none",
              fontWeight: "500",
              mt: 1.2,
              "&:hover": { background: "#272A40" },
            }}
          >
            {t("contactpop.button3")}
          </Button>
          <Typography
            sx={{
              my: 2,
              textTransform: "none",
              textDecoration: "underline",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={handleClose}
          >
            {t("common.closeWindow")}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Contact;
