import React from "react";
import Logout from "./Logout";
import SuccessView from "./SuccessView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import Login from "../Login";

export default function Home() {
  const session = useSessionContext();
  const navigate = useNavigate();

  if (session.loading) {
    return null;
  }

  return (
    <div className="fill">
      {/* <Login /> */}
      <SuccessView userId={session.userId} />
    </div>
  );
}
