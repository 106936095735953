import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Card,
  Divider,
  createTheme,
  Avatar,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import bg from "../../assets/images/bg.png";
import Slider from "react-slick";
import slider1 from "../../assets/images/banner-slider1.jpg";
import slider2 from "../../assets/images/banner-slider2.jpg";
import slider3 from "../../assets/images/banner-slider3.jpg";
import slider4 from "../../assets/images/banner-slider4.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cards from "./Cards";
import card1 from "../../assets/images/card-1.png";
import card2 from "../../assets/images/card-2.png";
import card3 from "../../assets/images/card-3.png";
import card4 from "../../assets/images/card-4.png";
import banner1 from "../../assets/images/banner-1.gif";
import banner2 from "../../assets/images/banner-2.gif";
import banner3 from "../../assets/images/banner-3.gif";
import banner4 from "../../assets/images/banner-4.gif";
import banner5 from "../../assets/images/banner-5.gif";

import { useMediaQuery } from "@mui/material";
import "./index.css";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../../components/common/LoginPopup";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import RulesPopUp from "./RulesPopUp";
import Contact from "../../components/common/Contact";
import { useTranslation } from "react-i18next";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../config/axios";

const LandingPage2 = () => {
  const [open, setOpen] = useState(false);
  const [openRule, setOpenRule] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const { t, i18n } = useTranslation();
  const navigation = useNavigate();
  const banners = [banner1, banner2, banner3, banner4];
  const session: any = useSessionContext();
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall2 = useMediaQuery(theme.breakpoints.down("sm"));
  const client = useAxiosPrivate(axios);
  const [loading, setLoading] = useState(false);
  const [bankData, setBankData] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleOpenRule = () => {
    setOpenRule(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRule = () => {
    setOpenRule(false);
  };
  const settings: any = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  const settings2: any = {
    dots: false,
    infinite: true,
    speed: 700,
    centerMode: false,
    slidesToShow: isSmall ? (isSmall2 ? 2.4 : 3.2) : 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 3000,
    rtl: false,
  };
  const settings3: any = {
    dots: false,
    infinite: true,
    speed: 700,
    centerMode: true,
    centerPadding: "10px",
    slidesToShow: isSmall ? (isSmall2 ? 2.2 : 3.1) : 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    variableWidth: false,
    autoplaySpeed: 3000,
    rtl: false,
  };

  const getBank = async () => {
    try {
      setLoading(true);
      const res = await client.get(API_ROUTES.GET_BANK);
      const temp: any = [];
      res.data.map((ele: any) => {
        const name = ele.name;
        const url = ele.thumbnail;
        const id = ele.id;
        temp.push({ name: name, url: url, id: id });
      });
      setBankData(temp);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (session?.userId) {
      navigation(APP_ROUTES.Dashboard);
    }
  }, [session]);
  useEffect(() => {
    getBank();
  }, []);
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 1 }}>
        <Box>
          <Box sx={{ display: "flex", gap: 1, mb: 2 }}>
            <Button
              variant="contained"
              sx={{
                width: "50%",
                py: "8px",
                background: "white",
                borderRadius: "0.5rem",
                "&:hover": {
                  background: "white",
                },
              }}
              onClick={() => {
                navigation(APP_ROUTES.Register);
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#cb0b36",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                {t("common.registercommon")}
              </Typography>
            </Button>
            <Button
              variant="outlined"
              sx={{
                width: "50%",
                py: "8px",
                border: "2px solid white",
                borderRadius: "0.5rem",
                "&:hover": {
                  border: "2px solid white",
                },
              }}
              onClick={() => {
                handleOpen();
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  fontWeight: "700",
                  textTransform: "none",
                }}
              >
                {t("common.login")}
              </Typography>
            </Button>
            <LoginPopup open={open} handleClose={handleClose} />
          </Box>
          <Box>
            <Slider {...settings}>
              <Box style={{ borderRadius: "0.5rem" }}>
                <img src={slider1} style={{ width: "100%" }} />
              </Box>
              <Box style={{ marginTop: "8px" }}>
                <img src={slider2} style={{ width: "100%" }} />
              </Box>
              <Box style={{ marginTop: "8px" }}>
                <img src={slider3} style={{ width: "100%" }} />
              </Box>
              <Box style={{ marginTop: "8px" }}>
                <img src={slider4} style={{ width: "100%" }} />
              </Box>
            </Slider>
          </Box>
          <Box sx={{ my: 1.5 }} className="cardSlides">
            <RulesPopUp openRule={openRule} handleCloseRule={handleCloseRule} />
            {!isSmall ? (
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                  <Cards text={t("landing.cards.first")} img={card1} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={handleOpenRule}
                  sx={{ cursor: "pointer" }}
                >
                  <Cards text={t("landing.cards.second")} img={card2} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Cards text={t("landing.cards.third")} img={card3} />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  onClick={() => {
                    setOpenContact(true);
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Cards text={t("landing.cards.contact")} img={card4} />
                </Grid>
                <Contact
                  open={openContact}
                  handleClose={() => {
                    setOpenContact(false);
                  }}
                />
              </Grid>
            ) : (
              <Slider {...settings3}>
                <Cards text={t("landing.cards.first")} img={card1} />
                <Cards text={t("landing.cards.second")} img={card2} />
                <Cards text={t("landing.cards.third")} img={card3} />
                <Cards text={t("landing.cards.contact")} img={card4} />
              </Slider>
            )}
          </Box>
          <Box sx={{ my: 2 }}>
            {/* <Grid container spacing={1}> */}
            {!isSmall ? (
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                {banners.map((ele) => (
                  // <Grid item xs={6} sm={3} key={ele}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      borderRadius: "0.5rem",
                    }}
                  >
                    <Box
                      component="img"
                      src={ele}
                      sx={{
                        width: "100%",
                        objectFit: "cover",
                        flexGrow: 1,
                      }}
                      alt="Banner"
                    />
                  </Card>
                ))}
              </Box>
            ) : (
              <Slider {...settings2}>
                {banners.map((ele) => (
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      borderRadius: "0.5rem",

                      boxShadow: "none",
                      backgroundColor: "transparent",
                    }}
                  >
                    <Box mx={1} sx={{ backgroundColor: "transparent" }}>
                      <Box
                        component="img"
                        src={ele}
                        sx={{
                          width: "100%",
                          objectFit: "cover",
                          flexGrow: 1,
                        }}
                        alt="Banner"
                      />
                    </Box>
                  </Card>
                ))}
              </Slider>
            )}
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              borderRadius: "20px",
              py: 3,
              gap: 2,
              justifyContent: "center",
              backgroundColor: " white",
            }}
          >
            {bankData.map((ele: any) => (
              <Box maxWidth={{ xs: "70px", sm: "100px" }}>
                <Box
                  component="img"
                  height={{ xs: "70px", sm: "100px" }}
                  width={{ xs: "70px", sm: "100px" }}
                  src={ele.url}
                />
                <Typography
                  variant="body2"
                  fontWeight={600}
                  textAlign="center"
                  sx={{ flexWrap: "wrap" }}
                >
                  {ele.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default LandingPage2;
