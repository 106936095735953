import React, { useEffect, useState } from "react";
import "./App.css";
import SuperTokens, {
  SuperTokensWrapper,
  redirectToAuth,
} from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { ThirdpartyEmailPasswordComponentsOverrideProvider } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import { ThirdPartyEmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/thirdpartyemailpassword/prebuiltui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import {
  SessionAuth,
  useSessionContext,
} from "supertokens-auth-react/recipe/session";
import Home from "./pages/Home";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import Footer from "./components/common/Footer";
import SessionExpiredPopup from "./SessionExpiredPopup";
import { LineIcon, PasswordLessIcon } from "./assets/images";
import LandingPage from "./pages/LandingPage";
import { API_ROUTES, APP_ROUTES } from "./utils/constants";
import { frontendConfig } from "./config/frontendConfig";
import Header from "./components/Header";
import LandingPage2 from "./pages/LandingPage2";
import bg from "./assets/images/bg.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RegisterPage from "./pages/RegisterPage";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";

import Dashboard from "./pages/Dashboard";
import InnerFooter from "./components/common/InnerFooter";
import Casino from "./pages/Casino";
import Login from "./pages/Login";
import Slots from "./pages/Slots";
import Sports from "./pages/Sports";
import Provider from "./pages/Provider";
import Wallet2 from "./pages/Wallet2";
import Profile2 from "./pages/Profile2";
import Topup from "./pages/Topup";
import Withdraw from "./pages/Withdraw";
import History from "./pages/History";
import Promotion from "./pages/Promotion/Promotion";
import BankPage from "./pages/BankPage";
import axios, { getHeaders } from "./config/axios";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GamePlay from "./pages/GamePlay";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import PopularGames from "./pages/PopularGames";

if (typeof window !== "undefined") {
  // we only want to call this init function on the frontend, so we check typeof window !== 'undefined'
  SuperTokens.init(frontendConfig());
}
function App() {
  let [showSessionExpiredPopup, updateShowSessionExpiredPopup] =
    useState(false);

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const [header, setHeader] = useState("");
  const session: any = useSessionContext();
  const client = useAxiosPrivate(axios);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const tempBank = localStorage.getItem("bankAdded") == "true" ? true : false;
  const [bankInfo, setBankInfo] = useState<any>(tempBank);

  const GetProfile = async () => {
    try {
      let response = await axios.get(API_ROUTES.Get_Profile);
      console.log("response ::", response);
      const mobile = await response.data.data.userInfo.mobile;
      const name = await response.data.data.userInfo.email;
      mobile
        ? localStorage.setItem("user", mobile)
        : localStorage.setItem("user", name);

      const bankFlags = response.data.data.userInfo.bankAccountAdded;
      localStorage.setItem("bankAdded", bankFlags ? "true" : "false");
      setBankInfo(bankFlags);
    } catch (error) {
    } finally {
    }
  };
  const GetHeader = async () => {
    // const token: any = JSON.stringify(await getHeaders());
    // localStorage.setItem("token", token);
    // console.log("token 2::", token);
    // setHeader(token);
  };
  // console.log(bankInfo, "bi");

  useEffect(() => {
    GetProfile();
    // GetHeader();
  }, [session?.userId]);

  useEffect(() => {
    setBankInfo(tempBank);
  }, [tempBank]);
  return (
    <>
      {/* <div className="App"> */}
      <div
        style={{
          height: "400px",
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          position: "relative",
          marginBottom: "10px",
        }}
      >
        <Header />
        {/* <div className="fill"> */}
        {/* <h3>{t("title")}</h3>
          <h5>{t("description.part2")}</h5>
          <Button type="button" onClick={() => changeLanguage("en")}>
            EN
          </Button>
          <Button type="button" onClick={() => changeLanguage("th")}>
            TH
          </Button> */}
        <div style={{ position: "relative" }}>
          <ThirdpartyEmailPasswordComponentsOverrideProvider
            components={{
              ThirdPartySignInAndUpProvidersForm_Override: ({
                DefaultComponent,
                ...props
              }: {
                DefaultComponent: any;
              }) => (
                <div>
                  <DefaultComponent {...props} />
                  <div
                    id="lineLoginBtn"
                    style={{
                      display: "flex",
                      // alignContent: "center",
                      justifyContent: "start",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      border: "1px",
                      borderStyle: "solid",
                      borderRadius: "8px",
                      cursor: "pointer",
                      maxWidth: "240px",
                      margin: "8px auto",
                      fontSize: "13px",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      redirectToAuth({
                        queryParams: {
                          rid: "thirdparty",
                          code: "1234567890abcde",
                        },
                        redirectBack: true,
                      });
                    }}
                  >
                    <img
                      src={LineIcon}
                      alt="Line"
                      width="24"
                      height="24"
                      style={{
                        borderRadius: "5px",
                        marginRight: "6px",
                        marginLeft: "44px",
                      }}
                      // className={styles.successIcon}
                    />{" "}
                    Continue with Line
                  </div>
                  <div
                    id="passwordlessLoginBtn"
                    style={{
                      display: "flex",
                      // alignContent: "center",
                      justifyContent: "start",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      border: "1px",
                      borderStyle: "solid",
                      borderRadius: "8px",
                      cursor: "pointer",
                      maxWidth: "240px",
                      margin: "16px auto 8px",
                      fontSize: "13px",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      redirectToAuth({
                        queryParams: {
                          rid: "passwordless",
                        },
                        redirectBack: false,
                      });
                    }}
                  >
                    <img
                      src={PasswordLessIcon}
                      alt="Line"
                      width="24"
                      height="24"
                      style={{
                        borderRadius: "5px",
                        marginRight: "6px",
                        marginLeft: "44px",
                      }}
                    />{" "}
                    Passwordless login
                  </div>
                </div>
              ),
            }}
          >
            <ToastContainer
              autoClose={3000}
              limit={2}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              // icon={false}
              pauseOnFocusLoss={false}
              draggable
              pauseOnHover={false}
              theme="light"
            />
            <Routes>
              {getSuperTokensRoutesForReactRouterDom(
                require("react-router-dom"),
                [
                  ThirdPartyEmailPasswordPreBuiltUI,
                  // EmailVerificationPreBuiltUI,
                  PasswordlessPreBuiltUI,
                ]
              )}
              <Route path="/" element={<LandingPage2 />} />
              <Route path={APP_ROUTES.Register} element={<RegisterPage />} />
              <Route path={APP_ROUTES.NotFound} element={<LandingPage2 />} />
              <Route path={APP_ROUTES.Forget} element={<ForgetPassword />} />
              <Route
                path={APP_ROUTES.ResetPassword}
                element={<ResetPassword />}
              />
              <Route path={"/auth/callback/google"} element={<Login />} />
              <Route
                path={APP_ROUTES.BankPage}
                element={
                  <SessionAuth
                    onSessionExpired={() => {
                      updateShowSessionExpiredPopup(true);
                    }}
                  >
                    <BankPage />
                  </SessionAuth>
                }
              />

              {bankInfo ? (
                <>
                  <Route
                    path={APP_ROUTES.Home}
                    element={
                      /* This protects the "/" route so that it shows 
                                    <Home /> only if the user is logged in.
                                    Else it redirects the user to "/auth" */
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Home />
                        {showSessionExpiredPopup && <SessionExpiredPopup />}
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Profile}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Profile2 />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Wallet}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Wallet2 />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Dashboard}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Dashboard />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Casino}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Casino />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Slots}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Slots />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Sports}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Sports />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Provider}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Provider />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Topup}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Topup />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Withdraw}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Withdraw />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.History}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <History />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.Promotion}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <Promotion />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.PlayGame}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <GamePlay />
                      </SessionAuth>
                    }
                  />
                  <Route
                    path={APP_ROUTES.PopularGames}
                    element={
                      <SessionAuth
                        onSessionExpired={() => {
                          updateShowSessionExpiredPopup(true);
                        }}
                      >
                        <PopularGames />
                      </SessionAuth>
                    }
                  />
                </>
              ) : (
                <Route
                  path="*"
                  element={
                    <SessionAuth
                      onSessionExpired={() => {
                        updateShowSessionExpiredPopup(true);
                      }}
                    >
                      <Navigate to={APP_ROUTES.BankPage} replace={true} />
                    </SessionAuth>
                  }
                />
              )}
            </Routes>
          </ThirdpartyEmailPasswordComponentsOverrideProvider>
          <Footer />
          <InnerFooter pathname={pathname} />
        </div>
      </div>
    </>
  );
}

export default App;
