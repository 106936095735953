import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

function formatDate(dateString: any) {
  const date = new Date(dateString); // Parse the date string into a Date object
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const period = date.getHours() >= 12 ? "pm" : "am";

  return dateString
    ? `${month}/${day}/${year} ${hours}:${minutes} ${period}`
    : "-";
}

// Add more rows with similar data here if needed
const GameTable = (props: any) => {
  const { data } = props;
  return (
    <TableContainer
      component={Paper}
      sx={{ width: { xs: "80%", md: "630px" }, margin: "0 auto" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Game Started</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Game Ended</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">Amount</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((ele: any, index: any) => (
            <TableRow key={index}>
              <TableCell>{formatDate(ele.startGameTime)}</TableCell>
              <TableCell>{formatDate(ele.endGameTime)}</TableCell>
              <TableCell>{ele.status}</TableCell>
              <TableCell>{ele.coins} coins</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GameTable;
