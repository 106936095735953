import {
  Avatar,
  Box,
  Button,
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CircleIcon from "@mui/icons-material/Circle";
import ClearIcon from "@mui/icons-material/Clear";
import selectLogo from "../../../assets/images/select_logo.png";
import { signOut } from "supertokens-auth-react/recipe/thirdpartyemailpassword";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import OutputOutlinedIcon from "@mui/icons-material/OutputOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import PermPhoneMsgOutlinedIcon from "@mui/icons-material/PermPhoneMsgOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import StarsOutlinedIcon from "@mui/icons-material/StarsOutlined";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, APP_ROUTES } from "../../../utils/constants";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import {
  Dashboard as DashboardIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Star as StarIcon,
  Language as LanguageIcon,
} from "@mui/icons-material";
import Contact from "../Contact";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const RightDrawer = () => {
  const navigate = useNavigate();
  const session: any = useSessionContext();
  const [tab, setTab] = useState(0);
  const [state, setState] = React.useState({
    right: false,
  });
  const [open, setOpen] = useState(false);

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  async function logoutClicked() {
    await signOut();
    localStorage.clear();
    navigate("/");
    toast.success("Logout successfully.");
  }

  const handleClick = () => {
    setOpen(!open);
  };
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };
  useEffect(() => {
    i18n.language == "th" ? setTab(1) : setTab(0);
    console.log(i18n.language, "language");
  }, []);
  return (
    <Box>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" sx={{ color: "white" }} />
      </Button>
      <Drawer anchor="right" open={state.right} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250, backgroundColor: "rgb(167 4 33/1)", flexGrow: 1 }}
          role="presentation"
          // onClick={toggleDrawer(false)}
          // onKeyDown={toggleDrawer(false)}
        >
          <Box sx={{ width: "90%", margin: "0 auto" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                cursor: "pointer",
              }}
            >
              <ClearIcon
                fontSize="large"
                sx={{ color: "white", mt: 1 }}
                onClick={toggleDrawer(false)}
              />
            </Box>
            <h1 style={{ textAlign: "center", color: "white" }}>PUG</h1>
            {session?.userId ? (
              <List>
                {[
                  {
                    text: t("sidebar.homepage"),
                    logo: StarsOutlinedIcon,
                    path: APP_ROUTES.Dashboard,
                  },
                  {
                    text: t("sidebar.wallet"),
                    logo: AccountBalanceWalletOutlinedIcon,
                    path: APP_ROUTES.Wallet,
                  },
                  {
                    text: t("sidebar.topup"),
                    logo: AddCircleOutlineOutlinedIcon,
                    path: APP_ROUTES.Topup,
                  },
                  {
                    text: t("sidebar.withdraw"),
                    logo: OutputOutlinedIcon,
                    path: APP_ROUTES.Withdraw,
                  },
                  {
                    text: t("sidebar.setting"),
                    logo: ManageAccountsOutlinedIcon,
                    path: APP_ROUTES.Profile,
                  },
                ].map((text, index) => (
                  <ListItem key={text.text} disablePadding sx={{ mt: 1 }}>
                    {/* <ListItemButton onClick={() =>{setOpenContact(true)}}>: */}
                    <ListItemButton
                      onClick={() => {
                        navigate(text.path);
                      }}
                    >
                      <text.logo
                        sx={{
                          width: "27px",
                          height: "27px",
                          color: "white",
                          mr: 2,
                        }}
                      />
                      <Typography variant="body1" color="white">
                        {text.text}
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                ))}
                <ListItem key="Language" disablePadding sx={{ mt: 1 }}>
                  <ListItemButton onClick={handleClick}>
                    <LanguageIcon
                      sx={{
                        color: "white",
                        mr: 2,
                      }}
                    />
                    <Typography variant="body1" color="white" sx={{ ml: 0.5 }}>
                      {t("sidebar.language")}
                    </Typography>
                  </ListItemButton>
                  {open ? (
                    <ExpandLessIcon sx={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "white" }} />
                  )}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ ml: 5.5 }}>
                    <ListItem
                      button
                      onClick={() => {
                        changeLanguage("en");
                        setTab(0);
                      }}
                      sx={{
                        backgroundColor: tab == 0 ? "white" : "",
                        "&:hover": { backgroundColor: tab == 0 ? "white" : "" },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            style={{ color: tab == 0 ? "#a70421" : "white" }}
                          >
                            {t("common.english")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        changeLanguage("th");
                        setTab(1);
                      }}
                      sx={{
                        backgroundColor: tab == 1 ? "white" : "",
                        "&:hover": { backgroundColor: tab == 1 ? "white" : "" },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            style={{ color: tab == 1 ? "#a70421" : "white" }}
                          >
                            {t("common.thai")}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem key="Log out" disablePadding sx={{ mt: 2 }}>
                  <ListItemButton onClick={logoutClicked}>
                    <LogoutOutlinedIcon
                      sx={{
                        width: "27px",
                        height: "27px",
                        color: "white",
                        mr: 2,
                      }}
                    />
                    <Typography variant="body1" color="white">
                      {t("sidebar.logout")}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              </List>
            ) : (
              <List>
                <ListItem key="Homepage" disablePadding sx={{ mt: 1 }}>
                  <ListItemButton onClick={() => navigate("/")}>
                    <StarsOutlinedIcon
                      sx={{
                        color: "white",
                        mr: 2,
                      }}
                    />
                    <Typography variant="body1" color="white" sx={{ ml: 2 }}>
                      {t("sidebar.homepage")}
                    </Typography>
                  </ListItemButton>
                </ListItem>
                <ListItem button onClick={handleClick}>
                  <ListItemIcon>
                    <LanguageIcon sx={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" sx={{ color: "white" }}>
                        {t("sidebar.language")}
                      </Typography>
                    }
                  />
                  {open ? (
                    <ExpandLessIcon sx={{ color: "white" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: "white" }} />
                  )}
                </ListItem>
                {/* Sub-Menu Items */}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ ml: 5.5 }}>
                    <ListItem
                      button
                      onClick={() => {
                        changeLanguage("en");
                        setTab(0);
                      }}
                      sx={{
                        backgroundColor: tab == 0 ? "white" : "",
                        "&:hover": { backgroundColor: tab == 0 ? "white" : "" },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            style={{ color: tab == 0 ? "#a70421" : "white" }}
                          >
                            {t("common.english")}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        changeLanguage("th");
                        setTab(1);
                      }}
                      sx={{
                        backgroundColor: tab == 1 ? "white" : "",
                        "&:hover": { backgroundColor: tab == 1 ? "white" : "" },
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            variant="body1"
                            style={{ color: tab == 1 ? "#a70421" : "white" }}
                          >
                            {t("common.thai")}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Collapse>
              </List>
            )}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default RightDrawer;
