import {
  Avatar,
  Box,
  Card,
  Container,
  Skeleton,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_ROUTES, APP_ROUTES, LEDGER } from "../../utils/constants";
import wallet from "../../assets/images/wallet.png";
import Topup from "../../assets/images/topup.png";
import Withdraw from "../../assets/images/withdraw.png";
import TranscationCard from "./TransactionCard";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import axios from "../../config/axios";
import { getApiDomain } from "../../config/frontendConfig";
import Loader from "../../components/common/Loader/Loader";
import InnerLoader from "../../components/common/Loader/InnerLoader";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";

const Wallet2 = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isSmall2 = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState(0);
  const session: any = useSessionContext();
  const client = useAxiosPrivate(axios);

  const [walletSummeryData, setWalletSummeryData] = useState<any>({});
  const [walletTransactionData, setWalletTransactionData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addData, setAddData] = useState([]);
  const [takenData, setTakenData] = useState([]);
  const [type, setType] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { t, i18n } = useTranslation();
  const skeletonArray = ["", "", "", "", "", ""];

  const GetWalletTransaction = async (page: any) => {
    try {
      setIsLoading(true);
      const dataBody = {
        playerId: session?.userId,
        type: type,
        page: page,
        limit: 10,
      };
      let response = await client.post(API_ROUTES.Get_History, dataBody);
      setWalletTransactionData((prevData: any) => [
        ...prevData,
        ...response.data.data,
      ]);

      if (response.data.data.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!isLoading) GetWalletTransaction(1);
  }, [type]);
  const handleNext = () => {
    const currentPage = page;
    setPage(currentPage + 1);
    GetWalletTransaction(currentPage + 1);
  };
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "white", fontWeight: "600" }}
        >
          {t("sidebar.wallet")}
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            gap: 1,
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "180px",
              width: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              background: selectedTab == 0 ? "white" : "#8B0724",
              borderRadius: "0.50rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setSelectedTab(0);
              setType("");
              setPage(1);
              setWalletTransactionData([]);
            }}
          >
            <Avatar src={wallet} sx={{ mb: 1 }} />
            <Typography
              color={selectedTab == 0 ? "#0b55ad" : "black"}
              variant="body2"
              sx={{ fontWeight: "600", textAlign: "center" }}
            >
              {t("wallet.button1")}
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "180px",
              width: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              background: selectedTab == 1 ? "white" : "#8B0724",
              borderRadius: "0.50rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setSelectedTab(1);
              setType("Deposite");
              setPage(1);
              setWalletTransactionData([]);
            }}
          >
            <Avatar src={Topup} sx={{ mb: 1 }} />
            <Typography
              color={selectedTab == 1 ? "#0b55ad" : "black"}
              variant="body2"
              sx={{ fontWeight: "600", textAlign: "center" }}
            >
              {t("wallet.button2")}
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "180px",
              width: "180px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              background: selectedTab == 2 ? "white" : "#8B0724",
              borderRadius: "0.50rem",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              setSelectedTab(2);
              setType("Withdrawal");
              setPage(1);
              setWalletTransactionData([]);
            }}
          >
            <Avatar src={Withdraw} sx={{ mb: 1 }} />
            <Typography
              color={selectedTab == 2 ? "#0b55ad" : "black"}
              variant="body2"
              sx={{ fontWeight: "600", textAlign: "center" }}
            >
              {t("wallet.button3")}
            </Typography>
          </Box>
        </Box>
        {selectedTab == 0 && (
          <Box
            sx={{
              backgroundColor: "#dde6f3",
              borderRadius: "0.75rem",
              mt: 3,
              pb: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                pt: 3,
                fontWeight: 600,
                color: "black",
              }}
            >
              {t("wallet.title1")}
            </Typography>
            <Box
              id="infinite-scroll"
              sx={{
                width: "90%",
                margin: "0 auto",
                mt: 2,
                height: "550px",
                overflowY: "scroll",
              }}
            >
              <InfiniteScroll
                dataLength={walletTransactionData.length}
                next={handleNext}
                hasMore={hasMore}
                loader={
                  isLoading &&
                  skeletonArray.map((ele: any) => (
                    <Box my={0.5}>
                      <Skeleton variant="rounded" width="100%" height={80} />
                    </Box>
                  ))
                }
                scrollableTarget="infinite-scroll"
              >
                {walletTransactionData?.map((ele: any) => (
                  <TranscationCard
                    key={ele.id}
                    addedAmount={ele?.amount}
                    isDeposite={ele.type != "Withdrawal"}
                    date={ele?.updatedAt}
                    status={ele?.status}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          </Box>
        )}
        {selectedTab == 1 && (
          <Box
            sx={{
              backgroundColor: "#dde6f3",
              borderRadius: "0.75rem",
              mt: 3,
              pb: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                pt: 3,
                fontWeight: 600,
                color: "black",
              }}
            >
              {t("wallet.title2")}
            </Typography>
            <Box
              id="infinite-scroll1"
              sx={{
                width: "90%",
                margin: "0 auto",
                mt: 2,
                height: "550px",
                overflowY: "scroll",
              }}
            >
              <InfiniteScroll
                dataLength={walletTransactionData.length}
                next={handleNext}
                hasMore={hasMore}
                loader={
                  isLoading &&
                  skeletonArray.map((ele: any) => (
                    <Box my={0.5}>
                      <Skeleton variant="rounded" width="100%" height={80} />
                    </Box>
                  ))
                }
                scrollableTarget="infinite-scroll1"
              >
                {walletTransactionData?.map((ele: any) => (
                  <TranscationCard
                    addedAmount={ele.amount}
                    date={ele?.updatedAt}
                    status={ele?.status}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          </Box>
        )}
        {selectedTab == 2 && (
          <Box
            sx={{
              backgroundColor: "#dde6f3",
              borderRadius: "0.75rem",
              mt: 3,
              pb: 4,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                pt: 3,
                fontWeight: 600,
                color: "black",
              }}
            >
              {t("wallet.title3")}
            </Typography>
            <Box
              id="infinite-scroll2"
              sx={{
                width: "90%",
                margin: "0 auto",
                mt: 2,
                height: "550px",
                overflowY: "scroll",
              }}
            >
              <InfiniteScroll
                dataLength={walletTransactionData.length}
                next={handleNext}
                hasMore={hasMore}
                loader={
                  isLoading &&
                  skeletonArray.map((ele: any) => (
                    <Box my={0.5}>
                      <Skeleton variant="rounded" width="100%" height={80} />
                    </Box>
                  ))
                }
                scrollableTarget="infinite-scroll2"
              >
                {walletTransactionData?.map((ele: any) => (
                  <TranscationCard
                    addedAmount={ele.amount}
                    isDeposite={!(ele.type == "Withdrawal")}
                    date={ele?.updatedAt}
                    status={ele?.status}
                  />
                ))}
              </InfiniteScroll>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Wallet2;
