import React from "react";
import { Rings } from "react-loader-spinner";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        gap: "20px",
      }}
    >
      <h1
        style={{
          color: "#ecc46b ",
        }}
      >
        PUG
      </h1>
      <Rings
        height="80"
        width="80"
        color="#f93"
        radius="6"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="rings-loading"
      />
    </div>
  );
};

export default Loader;
