import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Skeleton,
  SvgIcon,
  TextField,
  Typography,
  createTheme,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import kbank from "../../assets/images/KBANK.png";
import scb from "../../assets/images/SCB.png";
import AttachmentIcon from "@mui/icons-material/Attachment";
import { useTranslation } from "react-i18next";
import axios from "../../config/axios";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { API_ROUTES, APP_ROUTES } from "../../utils/constants";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Topup = () => {
  const theme = createTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const { t, i18n } = useTranslation();
  const client = useAxiosPrivate(axios);
  const session: any = useSessionContext();
  const [bankData, setBankData] = useState<any>({});
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const [loading3, setloading3] = useState(false);
  const [depositeImage, setDepositeImage] = useState<any>(null);
  const [imageBlob, setImageBlob] = useState("");
  const [playerBank, setPlayerBank] = useState<any>({});
  const [amount, setAmount] = useState(0);

  const navigate = useNavigate();

  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;
  const getBank = async () => {
    try {
      setloading(true);
      const res = await client.get(
        API_ROUTES.SUPPORT_BANK.replace(":userId", session?.userId)
      );
      setBankData({
        id: res.data?.id,
        name: res.data?.name,
        account: res.data?.accountNumber,
      });
      setloading(false);
      // console.log(res.data, "support bank");
    } catch (error) {
      console.log(error);
      setloading(false);
    } finally {
      setloading(false);
    }
  };
  const GetProfile = async () => {
    try {
      setloading2(true);
      let response = await axios.get(API_ROUTES.Get_Profile);
      console.log("response ::", response);
      const mobile = await response.data.data.userInfo.mobile;
      const name = await response.data.data.userInfo.email;
      mobile
        ? localStorage.setItem("user", mobile)
        : localStorage.setItem("user", name);

      const bankId = response.data.data.userInfo.bankId;
      setPlayerBank({
        id: bankId,
        account: response.data.data.userInfo.accountDetails.accountNumber,
        name:
          response.data.data.userInfo.accountDetails.firstName +
          " " +
          response.data.data.userInfo.accountDetails.lastName,
      });
      setloading2(false);
    } catch (error) {
      setloading2(false);
    }
  };
  useEffect(() => {
    getBank();
    GetProfile();
  }, []);

  const handleDeposite = async () => {
    try {
      setloading3(true);
      const formData: any = new FormData();
      formData.append("playerId", session?.userId);
      formData.append("playerBankId", playerBank.id);
      formData.append("pugsBankId", bankData.id);
      formData.append("receipt", depositeImage);
      formData.append("amount", amount);
      const res = await client.post(API_ROUTES.Add_Depostie, formData);
      console.log("respone ::", res.data);
      setloading3(false);
      toast.success("Top-up request added successfully.");
      navigate(APP_ROUTES.Wallet);
    } catch (error) {
      setloading3(false);
      toast.error("Oops! Something went wrong.");
    }
  };
  const handleFileChange = async (event: any) => {
    if (!event.target.files[0]) {
      toast.error("Please Select Image");
      return;
    }
    setDepositeImage(event.target.files[0]);
    const image = URL.createObjectURL(event.target.files[0]);
    setImageBlob(image);
    console.log(event.target.files[0], "file");
  };
  // console.log(imageBlob, "IB");
  return (
    <Box>
      <Container maxWidth={isSmall ? "sm" : "md"} sx={{ mt: 3 }}>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", color: "white", fontWeight: "600", my: 2 }}
        >
          {t("topup.title1")}
        </Typography>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#8B0724",
            borderRadius: "0.75rem",
          }}
        >
          {!loading2 ? (
            <CardMedia
              component="img"
              src={kbank} // Replace with your image URL
              alt="Image"
              sx={{ width: 60, height: 60, ml: 3 }} // Adjust image dimensions and spacing
            />
          ) : (
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              sx={{ ml: 3 }}
            />
          )}
          <CardContent sx={{ pb: "8px !important", p: "8px" }}>
            <Typography variant="body2" style={{ color: "#FFDA16" }}>
              {!loading2 ? (
                t("topup.title2")
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={150}
                />
              )}
            </Typography>
            {!loading2 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 1,
                }}
              >
                <Typography color="white" variant="h6">
                  {playerBank.account}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(playerBank.account);
                    toast.success("copy to clipboard.");
                  }}
                  sx={{
                    textTransform: "none",
                    p: 0,
                    color: "white",
                    border: "1px solid white",
                    "&:hover": {
                      border: "1px solid white !important",
                    },
                  }}
                >
                  {t("topup.button5")}
                </Button>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
            )}
            {!loading2 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography color="white" variant="body1">
                  {playerBank.name}
                </Typography>

                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(playerBank.name);
                    toast.success("copy to clipboard.");
                  }}
                  sx={{
                    textTransform: "none",
                    p: 0,
                    color: "white",
                    border: "1px solid white",
                    "&:hover": {
                      border: "1px solid white !important",
                    },
                  }}
                >
                  {t("topup.button5")}
                </Button>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
            )}
          </CardContent>
        </Card>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#8B0724",
            borderRadius: "0.75rem",
            my: 1.5,
          }}
        >
          <CardContent
            sx={{
              pb: "8px !important",
              p: "8px",
              margin: "0 auto",
            }}
          >
            <Typography variant="body2" color="#FFDA16">
              {t("topup.title3")}
            </Typography>
          </CardContent>
        </Card>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#EEEFFF",
            borderRadius: "0.75rem",
            mb: 1,
          }}
        >
          {!loading ? (
            <CardMedia
              component="img"
              src={scb} // Replace with your image URL
              alt="Image"
              sx={{ width: 60, height: 60, ml: 3 }} // Adjust image dimensions and spacing
            />
          ) : (
            <Skeleton
              variant="circular"
              width={60}
              height={60}
              sx={{ ml: 3 }}
            />
          )}
          <CardContent sx={{ pb: "8px !important", p: "8px" }}>
            <Typography variant="body2">
              {!loading ? (
                t("topup.title4")
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={150}
                />
              )}
            </Typography>
            {!loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 1,
                }}
              >
                <Typography variant="h6">{bankData.account}</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(bankData.account);
                    toast.success("copy to clipboard.");
                  }}
                  sx={{
                    textTransform: "none",
                    p: 0,
                    color: "black",
                    border: "1px solid black",
                    "&:hover": {
                      border: "1px solid black !important",
                    },
                  }}
                >
                  {t("topup.button5")}
                </Button>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
            )}
            {!loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  mb: 1,
                }}
              >
                <Typography variant="h6">{bankData.name}</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(bankData.name);
                    toast.success("copy to clipboard.");
                  }}
                  sx={{
                    textTransform: "none",
                    p: 0,
                    color: "black",
                    border: "1px solid black",
                    "&:hover": {
                      border: "1px solid black !important",
                    },
                  }}
                >
                  {t("topup.button5")}
                </Button>
              </Box>
            ) : (
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={150} />
            )}
          </CardContent>
        </Card>
        <Typography variant="body1" fontWeight="600" ml={1}>
          {t("topup.title5")}
        </Typography>
        <Card
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#DDE6F3",
            borderRadius: "0.75rem",
            my: 1,
            p: depositeImage ? 0 : 14,
          }}
        >
          <CardContent
            sx={{
              pb: "8px !important",
              p: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {!depositeImage ? (
              <>
                <AttachmentIcon sx={{ fontSize: "36px" }} />
                <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                  {t("topup.title6")}
                </Typography>
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  variant="outlined"
                  sx={{ color: "nautral", textTransform: "none", mt: 1 }}
                  size="small"
                >
                  {t("topup.button1")}
                  <input
                    accept="image/*"
                    hidden
                    id="raised-button-file"
                    onChange={handleFileChange}
                    type="file"
                  />
                  <VisuallyHiddenInput type="file" />
                </Button>
              </>
            ) : (
              <Box
                component="img"
                width="50%"
                src={imageBlob}
                sx={{ objectFit: "contain" }}
              />
            )}
          </CardContent>
        </Card>
        <TextField
          variant="outlined"
          size="small"
          value={amount}
          type="number"
          fullWidth
          sx={{
            backgroundColor: "white",
            textAlign: "center",
            mb: 1,
          }}
          inputProps={{
            sx: { color: "#0677e5", textAlign: "center" },
          }}
          placeholder="Enter your amount here..."
          onChange={(e: any) => {
            setAmount(e.target.value);
          }}
        />

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              size="large"
              variant="contained"
              fullWidth
              disabled={!depositeImage || loading3 || !amount}
              sx={{
                backgroundColor: "#d3114c",
                textTransform: "none",
                "&:hover": { backgroundColor: "#d3114c" },
              }}
              onClick={handleDeposite}
            >
              {loading3 ? ( // Render the loader when loading is true
                <CircularProgress size={24} sx={{ color: "white" }} />
              ) : (
                t("topup.button2")
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="large"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#272A40",
                textTransform: "none",
                "&:hover": { backgroundColor: "#272A40" },
              }}
              onClick={() => navigate(APP_ROUTES.Dashboard)}
            >
              {t("topup.button3")}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size="large"
              variant="contained"
              fullWidth
              disabled={!depositeImage}
              sx={{
                backgroundColor: "#D02F2F",
                textTransform: "none",
                whiteSpace: "nowrap",
                "&:hover": { backgroundColor: "#D02F2F" },
              }}
              onClick={() => {
                setDepositeImage(null);
              }}
            >
              {t("topup.button4")}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Topup;
