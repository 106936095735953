import { useEffect } from "react";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { useSessionContext } from "supertokens-auth-react/recipe/session";

export const Logout = () => {
  localStorage.removeItem("auth");
};

const useAxiosPrivate = (axiosInstance: AxiosInstance) => {
  // const refresh = useRefreshToken();
  const session = useSessionContext();

  useEffect(() => {
    const requestIntercept = axiosInstance.interceptors.request.use(
      // @ts-ignore: Unreachable code error
      (config: AxiosRequestConfig) => {
        // if (!config.headers!["Authorization"]) {
        //   config.headers!["Authorization"] = `bearer ${auth?.accessToken}`;
        // }
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    const responseIntercept = axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        // const prevRequest = error?.config;
        // if (
        //   (error?.response?.status === 403 ||
        //     error?.response?.status === 401) &&
        //   !prevRequest?.sent
        // ) {
        //   prevRequest.sent = true;
        //   const newAccessToken = await refresh();
        //   if (!newAccessToken) {
        //     // dispatch(logout());
        //     Logout();
        //   } else {
        //     prevRequest.headers["Authorization"] = `bearer ${newAccessToken}`;
        //     return axiosInstance(prevRequest);
        //   }
        // }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(requestIntercept);
      axiosInstance.interceptors.response.eject(responseIntercept);
    };
  }, [session]);

  return axiosInstance;
};

export default useAxiosPrivate;
