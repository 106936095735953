import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const TransactionGame = ({
  isDeposite = "",
  addedAmount = 50,
  date = "",
  provider = "provider name",
  game = "game name",
}) => {
  function formatDate(dateString: any) {
    const date = new Date(dateString); // Parse the date string into a Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours() % 12 || 12).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const period = date.getHours() >= 12 ? "pm" : "am";

    return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;
  }
  const newDate = formatDate(date);
  const { t, i18n } = useTranslation();
  return (
    <Card variant="outlined" sx={{ borderRadius: "0.75rem", pb: "0", my: 0.5 }}>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: "16px !important",
        }}
      >
        <Box>
          {isDeposite == "deposite" ||
          isDeposite == "withdraw" ||
          isDeposite == "manual-add" ||
          isDeposite == "manual-remove" ? (
            <>
              <Typography variant="h6" color="primary">
                {isDeposite == "deposite" && t("wallet.transcation.title1")}
                {isDeposite == "withdraw" && t("wallet.transcation.title4")}
                {isDeposite == "manual-add" && t("history.title2")}
                {isDeposite == "manual-remove" && t("history.title3")}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" color="textSecondary">
                {t("playback.title2")} : {provider}
              </Typography>
              <Typography
                variant="h6"
                color="primary"
                component="span"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {t("playback.title3")} : {game}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          {isDeposite == "game-win" ||
          isDeposite == "deposite" ||
          isDeposite == "manual-add" ? (
            <>
              <Typography variant="h6" component="span" sx={{ color: "green" }}>
                +{addedAmount}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {newDate}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h6" component="span" sx={{ color: "Red" }}>
                -{addedAmount}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {newDate}
              </Typography>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TransactionGame;
